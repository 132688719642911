// TradingViewWidget.jsx

import React, { useEffect, useRef } from "react"
import { useExchangeContext } from "src/contexts/ExchangeContext"

let tvScriptLoadingPromise
const pairMapping = {
  "BTC/USD": "Binance:BTCUSDT",
  "WETH/USD": "Binance:ETHUSDT",
  "APT/USD": "Binance:APTUSDT",
  "SUI/USD": "Binance:SUIUSDT",
  "DOGE/USD": "Binance:DOGEUSDT",
  "PEPE/USD": "Binance:PEPEUSDT",
  "SEI/USD": "Binance:SEIUSDT",
  "CYBER/USD": "Binance:CYBERUSDT"
}

export default function TradingViewChart() {
  const onLoadScriptRef = useRef()
  const { pair } = useExchangeContext()

  const iframeSource = `
  <style>
    #dexscreener-embed {
      width: 100%;
    }

    #dexscreener-embed iframe {
      position:absolute;
      width:100%;
      height:100%;
      top:0;
      left:0;
      border:0;
    }
  </style>

  <div id="dexscreener-embed">
    <iframe src="https://dexscreener.com/base/0xAccf18e9154Df39dd91E35D40B3585f5c648ffac?embed=1&theme=dark&trades=0&info=0">
    </iframe>
  </div>
  `

  useEffect(() => {
    onLoadScriptRef.current = createWidget

    if (!tvScriptLoadingPromise) {
      tvScriptLoadingPromise = new Promise((resolve) => {
        const script = document.createElement("script")
        script.id = "tradingview-widget-loading-script"
        script.src = "https://s3.tradingview.com/tv.js"
        script.type = "text/javascript"
        script.onload = resolve
        document.head.appendChild(script)
      })
    }

    tvScriptLoadingPromise.then(() => onLoadScriptRef.current && onLoadScriptRef.current())

    return () => (onLoadScriptRef.current = null)

    function createWidget() {
      if (document.getElementById("tradingview_1327e") && "TradingView" in window) {
        new window.TradingView.widget({
          width: "100%",
          height: "100%",
          symbol: pairMapping[pair] || "Binance:BTCUSDT",
          interval: "D",
          timezone: "Etc/UTC",
          theme: "dark",
          style: "1",
          locale: "en",
          disabled_features: ["create_volume_indicator_by_default"],
          toolbar_bg: "#121217",
          enable_publishing: false,
          allow_symbol_change: true,
          container_id: "tradingview_1327e",
          hide_legend: true
        })
      }
    }
  }, [pair])

  if (pair === "RISKY/USD") {
    return (
      <div className="tradingview-widget-container relative">
        <div className="Riskychart" dangerouslySetInnerHTML={{ __html: iframeSource }}></div>
      </div>
    )
  }

  return (
    <div className="tradingview-widget-container">
      <div id="tradingview_1327e" />
    </div>
  )
}
