import { useMemo, useEffect, useState } from "react"
import { usePublicClient, erc20ABI } from "wagmi"

const useDecimals = (tokenAddresses) => {
  const [data, setData] = useState([])

  const publicClient = usePublicClient()

  const fetchDecimals = async () => {
    try {
      const results = await publicClient.multicall({
        contracts: tokenAddresses.map((tokenAddress) => ({
          address: tokenAddress,
          abi: erc20ABI,
          functionName: "decimals",
          args: []
        }))
      })

      setData(results)
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    fetchDecimals()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenAddresses])

  const decimals = useMemo(() => {
    const result = {}

    if (!data) {
      return result
    }

    data.forEach((item, index) => {
      result[tokenAddresses[index]] = item.result
    })

    return result
  }, [data, tokenAddresses])

  return { decimals }
}

export default useDecimals
