import React, { useEffect, useState } from "react"
import cx from "classnames"

import LoadingLine from "../LoadingLine/LoadingLine"

const NoticePopup = ({ body, position = "center", type = "default", showLoadingLine = true }) => {
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    setIsVisible(true)
  }, [])

  return (
    <div
      className={cx("card shadow fixed w-96 rounded p-2 flex flex-col gap-2 text-sm transition-opacity z-50", {
        "top-20 left-1/2 -translate-x-1/2": position === "center",
        "bottom-3 right-3": position === "bottom-right",
        "top-3 left-3": position === "top-left",
        "bottom-3 left-3": position === "bottom-left",
        "border-green": type === "success",
        "border-red": type === "error",
        "border-yellow": type === "pending",
        "border-default": type === "default",
        "fade-in": true,
        visible: isVisible
      })}
    >
      {body}
      {showLoadingLine && <LoadingLine type={type} />}
    </div>
  )
}

export default NoticePopup
