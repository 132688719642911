/* global BigInt */
import React, { useEffect, useMemo, useState, useCallback } from "react"

import { Position, OrderType } from "@void-0x/void-sdk"
import { parseUnits, stringToHex } from "viem"
import { useAccount, useBalance, useNetwork } from "wagmi"

import { SelectCustom, InputCustom, SliderLeverage, SlippageCustom } from "@components/common"
import { LimitIcon, StopMarket, MarketIcon } from "@icons/index"
import CollateralModal from "@components/CollateralModal/CollateralModal"
import Button from "@components/Button/Button"
import SwitchButton from "@components/SwitchButton/SwitchButton"
import { Constants } from "@void-0x/void-sdk"

import InputWithToken from "@components/common/InputWithToken/InputWithToken"
import useAllowance from "src/hooks/useAllowance"
import useDebounce from "src/hooks/useDebounce"
import { useTokenPrice } from "src/hooks/useTokenPriceFeed"
import { useExchangeContext } from "src/contexts/ExchangeContext"
import { formatDollar, formatValue } from "src/lib/formatter"
import { getCollateralValue } from "src/lib/utils"
import { useExchangeUIContext } from "src/contexts/ExchangeUIContext"
import ConfirmPlaceOrderModal from "./ConfirmPlaceOrderModal"
import ProfitStopLossModal from "./ProfitStopLossModal"

import cx from "classnames"
import ArrowTrendingUpIcon from "@img/icons/ArrowTrendingUp.svg"
import ArrowTrendingDown from "@img/icons/ArrowTredingDown.svg"
import { OrderTypes, MarketTypes } from "src/lib/market"
import { MapSymbolToIcon, AddressToSymbolMap, getSymbolFromPairName, Tokens } from "src/lib/tokens"
import { detectDecimals } from "src/lib/decimal"

const OrderBox = () => {
  const { placeOrder, isPlacingOrder, side, setSide, market, pair } = useExchangeContext()
  const { setModalPlaceOrderConfirm, setListOrders, handleModalStoploss, setCollateralModal, collateralModal } =
    useExchangeUIContext()
  const indexPrice = useTokenPrice(market?.indexToken)

  // UI state
  // const [isToggled, setIsToggled] = useState(false)
  const [selectedToken, setSelectedToken] = useState()
  const [orderConfirmModal, setOrderConfirmModal] = useState(false)
  const [isShowStopLossInput, setIsShowStopLossInput] = useState(false)

  // Order state
  const [payAmount, setPayAmount] = useState("")
  const [triggerPrice, setTriggerPrice] = useState("")
  const [takeProfitPrice, setTakeProfitPrice] = useState(0)
  const [stopLossPrice, setStopLossPrice] = useState(0)
  const [leverage, setLeverage] = useState(10)
  const [orderType, setOrderType] = useState(OrderType.MARKET)

  const { chain } = useNetwork()
  const { address, isConnected } = useAccount()

  const tokenOptions = useMemo(() => {
    if (!market || !market.name) return []
    const tokens = []

    // if this is a synthetic market, only show the quote token, usually USDC
    if (market && market.marketType === MarketTypes.Synthetic) {
      tokens.push(market.quoteToken)
    } else {
      // if this is regular market, show both index and quote token
      tokens.push(market.indexToken)
      tokens.push(market.quoteToken)
    }

    return tokens.map((tokenAddr) => {
      const symbol = AddressToSymbolMap[chain?.id][tokenAddr]
      const token = Tokens[chain?.id][symbol]
      return { label: token?.name, value: token?.address, icon: token?.icon }
    })
  }, [chain?.id, market])

  const collateralInfo = useMemo(() => {
    if (chain) {
      const tokenName = AddressToSymbolMap[chain?.id]?.[selectedToken]
      const collateralToMap = {
        icon: MapSymbolToIcon[tokenName]
      }
      return collateralToMap
    }
    return {}
  }, [chain, selectedToken])

  const { data: balance } = useBalance({
    address: address,
    token: selectedToken,
    watch: true,
    staleTime: 2_000
  })

  const collateralPrice = useTokenPrice(selectedToken)

  const { allowance, approve, isApproving } = useAllowance({
    token: selectedToken,
    account: address,
    spender: Constants.Addresses[chain?.id]?.Exchange,
    tokenDecimals: balance?.decimals || 0
  })

  const onApprove = React.useCallback(() => {
    approve(payAmount)
  }, [payAmount, approve])

  const onDebounceApprove = useDebounce(onApprove, 1000)

  const onChangeOrderType = (order) => {
    setOrderType(order)
  }

  const onChangeTriggerPrice = (price) => {
    setTriggerPrice(price)
  }

  const onChangeTakeProfitStopLoss = () => {
    setIsShowStopLossInput((prev) => !prev)
  }
  useEffect(() => {
    if (orderType !== OrderType.MARKET) {
      const price = Number(indexPrice) / 1e18
      const decimals = detectDecimals(price)
      const priceToUse = price.toFixed(decimals).toString()
      setTriggerPrice(priceToUse)
    } else {
      setTriggerPrice("")
    }
  }, [indexPrice, orderType])

  const positionSize = useMemo(() => {
    if (payAmount) {
      return Position.getPositionSizeInUsd(
        parseUnits(payAmount?.toString(), balance?.decimals),
        collateralPrice,
        Number(leverage),
        balance?.decimals
      )
    }
    return 0
  }, [balance, collateralPrice, leverage, payAmount])

  const collateralValue = useMemo(() => {
    const collateral = getCollateralValue(parseUnits(payAmount?.toString(), balance?.decimals), collateralPrice)
    const valueUsd = collateral ? formatValue(collateral, balance?.decimals + Constants.ORACLE_PRICE_DECIMALS) : "0.0"
    return valueUsd
  }, [balance?.decimals, collateralPrice, payAmount])

  useEffect(() => {
    if (market && market.marketType === MarketTypes.Synthetic) {
      setSelectedToken(market?.quoteToken)
    } else {
      setSelectedToken(market?.indexToken)
    }
  }, [market, market?.indexToken, market?.marketType, market?.quoteToken])

  const handleConfirmOrder = useCallback(() => {
    const purchaseInfo = tokenOptions.find((t) => t.value === selectedToken)

    setModalPlaceOrderConfirm({
      enabled: true,
      data: {
        orderType: orderType,
        indexPrice: indexPrice ? formatValue(indexPrice, Constants.USD_DECIMALS) : 0,
        payAmount: payAmount,
        purchaseIcon: purchaseInfo?.icon,
        purchaseLabel: purchaseInfo?.label,
        leverage: leverage,
        positionSize: positionSize,
        side: side
      }
    })
    setOrderConfirmModal(true)
  }, [
    tokenOptions,
    setModalPlaceOrderConfirm,
    orderType,
    indexPrice,
    payAmount,
    leverage,
    positionSize,
    side,
    selectedToken
  ])

  const onPlaceOrder = useCallback(async () => {
    const precision = 10 ** balance?.decimals

    const triggerPriceBigInt = parseUnits(triggerPrice.toString(), Constants.ORACLE_PRICE_DECIMALS)
    const payAmountBigInt = parseUnits(payAmount.toString(), balance?.decimals)
    const takeProfitPriceBigInt = parseUnits(takeProfitPrice.toString(), Constants.USD_DECIMALS)
    const stopLossPriceBigInt = parseUnits(stopLossPrice.toString(), Constants.USD_DECIMALS)
    const sizeDelta = (BigInt(leverage) * getCollateralValue(payAmountBigInt, collateralPrice)) / BigInt(precision)
    const collateralAmount = parseUnits(payAmount?.toString(), balance?.decimals)

    const pairName = getSymbolFromPairName(pair)
    const icon = MapSymbolToIcon[pairName]

    const newOrder = {
      orderId: Math.random(),
      orderType: OrderTypes[orderType],
      side: side,
      collateralToken: selectedToken,
      collateralAmount: collateralAmount,
      leverage: leverage + "x",
      indexPrice: formatValue(indexPrice, Constants.USD_DECIMALS),
      token: icon,
      action: "open"
    }

    await placeOrder({
      orderType: orderType,
      marketId: market.id,
      side: side,
      collateralToken: selectedToken,
      collateralAmount: collateralAmount,
      sizeDelta,
      referalCode: stringToHex("", { size: 32 }),
      extra: {
        triggerPrice: orderType === OrderType.MARKET ? BigInt(0) : triggerPriceBigInt,
        tpPrice: orderType === OrderType.MARKET ? BigInt(0) : takeProfitPriceBigInt,
        slPrice: orderType === OrderType.MARKET ? BigInt(0) : stopLossPriceBigInt,
        tpDelta: takeProfitPriceBigInt > 0 ? sizeDelta : 0,
        slDelta: stopLossPriceBigInt < 0 ? sizeDelta : 0
      }
    })

    setListOrders((prev) => [...prev, newOrder])

    setTimeout(() => {
      setListOrders([])
    }, 3000)

    setPayAmount("")
    if (!isPlacingOrder) {
      setOrderConfirmModal(false)
      setModalPlaceOrderConfirm({ enabled: false, data: null })
    }
  }, [
    balance?.decimals,
    triggerPrice,
    payAmount,
    takeProfitPrice,
    stopLossPrice,
    leverage,
    collateralPrice,
    pair,
    orderType,
    side,
    selectedToken,
    indexPrice,
    setListOrders,
    placeOrder,
    market?.id,
    isPlacingOrder,
    setModalPlaceOrderConfirm
  ])

  const onChangeTakeProfitPrice = (price) => {
    setTakeProfitPrice(price)
  }

  const onChangeStopLossPrice = (price) => {
    setStopLossPrice(price)
  }

  const onChangeTab = (side) => {
    setSide(side)
    setPayAmount("")
  }

  const renderButton = useCallback(() => {
    if (payAmount === "" || +payAmount === 0) {
      return (
        <Button
          text="Enter amount"
          className={cx("cursor-not-allowed py-2", { " bg-green": side === 0, "bg-red": side === 1 })}
          isDefault={false}
          isShadow={false}
        />
      )
    }

    if (+allowance >= +payAmount) {
      return (
        <Button
          className={cx("w-full py-2 cursor-pointer", { " bg-green": side === 0, "bg-red": side === 1 })}
          text={side === 0 ? "Long" : "Short"}
          onClick={handleConfirmOrder}
          isDefault={false}
          disabled={payAmount === "" || payAmount === 0 || isPlacingOrder}
          isShadow={false}
        />
      )
    }

    return (
      <Button
        className="w-full"
        text="Approve"
        onClick={onDebounceApprove}
        isLoading={isApproving}
        disabled={isApproving}
        isShadow={false}
      />
    )
  }, [payAmount, allowance, onDebounceApprove, isApproving, handleConfirmOrder, isPlacingOrder, side])

  return (
    <>
      <CollateralModal />
      <ProfitStopLossModal />
      <ConfirmPlaceOrderModal
        open={orderConfirmModal}
        setOpen={setOrderConfirmModal}
        disabled={isPlacingOrder}
        onPlaceOrder={onPlaceOrder}
        isPlacingOrder={isPlacingOrder}
      />
      <div>
        <div className="tab flex vh-7 border-b">
          <div
            className={cx("w-1/2 text-center  cursor-pointer flex items-center justify-center gap-1", {
              "active-long": side === 0
            })}
            onClick={() => onChangeTab(0)}
          >
            <img src={ArrowTrendingUpIcon} alt="icon" className="w-5" />
            <span className="text-success">Long</span>
          </div>
          <div
            className={cx("w-1/2 text-center  cursor-pointer flex items-center justify-center gap-1", {
              "active-short": side === 1
            })}
            onClick={() => onChangeTab(1)}
          >
            <img src={ArrowTrendingDown} alt="icon" className="w-5" />
            <span className="text-error">Short</span>
          </div>
        </div>
        <div className="p-3 vh-83 overflow-y-auto no-scrollbar">
          <div className="order-box flex flex-col gap-3 ">
            <div className="grid grid-cols-2 gap-2">
              <div className="">
                <SelectCustom
                  label="Order Type"
                  options={[
                    { label: "Limit", value: OrderType.LIMIT, icon: LimitIcon },
                    { label: "Market", value: OrderType.MARKET, icon: MarketIcon },
                    { label: "Stop Market", value: OrderType.STOP_MARKET, icon: StopMarket }
                  ]}
                  defaultValue="market"
                  values={orderType}
                  classNameInput="pr-2"
                  onChange={onChangeOrderType}
                />
              </div>

              <div className="">
                <InputCustom
                  label={
                    orderType === OrderType.MARKET
                      ? "Price"
                      : orderType === OrderType.LIMIT
                      ? "Limit Price"
                      : "Stop Price"
                  }
                  placeHolder={
                    indexPrice && orderType === OrderType.MARKET
                      ? formatValue(indexPrice, Constants.USD_DECIMALS)
                      : "0.0"
                  }
                  classNameInput="px-1 py-2"
                  disabled={orderType === OrderType.MARKET}
                  onChange={onChangeTriggerPrice}
                  leftSide={
                    orderType === OrderType.LIMIT ? (
                      <div className="flex items-center gap-1">
                        <span className="text-slate-500">≤</span> <span>$</span>
                      </div>
                    ) : orderType === OrderType.STOP_MARKET ? (
                      <div className="flex items-center gap-1">
                        <span className="text-slate-500">≥</span> <span>$</span>
                      </div>
                    ) : (
                      ""
                    )
                  }
                  value={triggerPrice}
                  type="number"
                  isShowCommaNumber={true}
                />
              </div>
            </div>
            <div className="relative flex flex-col gap-1">
              <div className="flex items-center gap-2">
                <h3 className="text-sm text-slate-500">Pay</h3>
                <SwitchButton onChange={() => setCollateralModal(!collateralModal)} value={collateralModal} />
              </div>

              <InputWithToken
                tokenOptions={tokenOptions}
                token={selectedToken}
                onSelectToken={(token) => {
                  setSelectedToken(token)
                }}
                onChangeInput={(val) => setPayAmount(val)}
                inputValue={payAmount}
                disabled={isApproving || isPlacingOrder || !isConnected}
              />
            </div>
            <div className="border rounded px-2 pt-2 pb-7">
              <SliderLeverage
                label="Leverage"
                defaultValue={20}
                onChangeLeverage={(amount) => setLeverage(amount)}
                value={leverage}
              />
            </div>
            <div className="flex flex-col gap-2">
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-2">
                  <input
                    id="profit-toploss"
                    type="checkbox"
                    onChange={onChangeTakeProfitStopLoss}
                    checked={isShowStopLossInput}
                  />
                  <h3 htmlFor="profit-toploss" className="text-sm font-medium text-slate-500 cursor-pointer">
                    Take Profit / Top Loss
                  </h3>
                </div>
                <h3
                  className="text-default dotted-underline cursor-pointer text-sm"
                  onClick={() => handleModalStoploss(side)}
                >
                  Advanced
                </h3>
              </div>
              {isShowStopLossInput && (
                <div className="flex items-center gap-2">
                  <InputCustom
                    label="Take Profit"
                    leftSide={
                      <div className="flex items-center gap-1">
                        <span className="text-slate-500">TP:</span>
                        <span className="text-slate-500">≥</span>
                        <span>$</span>
                      </div>
                    }
                    classNameInput="py-4 px-1"
                    onChange={onChangeTakeProfitPrice}
                  />

                  <InputCustom
                    label="Stop Loss"
                    leftSide={
                      <div className="flex items-center gap-1">
                        <span className="text-slate-500">SL:</span>
                        <span className="text-slate-500">≤</span>
                        <span>$</span>
                      </div>
                    }
                    classNameInput="py-4 px-1"
                    onChange={onChangeStopLossPrice}
                  />
                </div>
              )}
            </div>
            <div className="w-full mt-5">{renderButton()}</div>
            <div className="mt-3">
              <SlippageCustom
                label="Slippage"
                options={[
                  { label: "0.1", value: 0.1 },
                  { label: "0.2", value: 0.2 },
                  { label: "0.3", value: 0.3 }
                ]}
                defaultValue={0.1}
              />
            </div>
            <div className="flex flex-col gap-3">
              <div className="flex justify-between text-sm">
                <h3 className="text-slate-500 dotted-underline cursor-pointer">Entry Price</h3>
                <span>{indexPrice ? formatValue(indexPrice, Constants.USD_DECIMALS) : "0.0"}</span>
              </div>
              <div className="collateral-asset flex justify-between text-sm">
                <h3 className="text-slate-500 dotted-underline">Collateral Asset</h3>
                {collateralInfo ? (
                  <div className="flex items-center gap-1">
                    <img src={collateralInfo?.icon} className="rounded-full w-5 h-5" alt="icon" />
                  </div>
                ) : (
                  "-"
                )}
              </div>
              {orderType !== OrderType.MARKET && (
                <div className="flex justify-between text-sm">
                  <h3 className="text-slate-500 dotted-underline">Est. Trigger & Fill Price</h3>
                  <div>-</div>
                </div>
              )}
              <div className="collateral-value flex justify-between text-sm">
                <h3 className="text-slate-500 dotted-underline">Collateral Value</h3>
                <span>{collateralValue}</span>
              </div>
              <div className="flex justify-between items-center">
                <h3 className="text-sm text-slate-500 dotted-underline">Position Size</h3>

                <div className="text-sm">{positionSize > 0 ? formatDollar(positionSize) : "-"}</div>
              </div>

              <div className="collateral-leverage flex justify-between text-sm">
                <h3 className="text-slate-500 dotted-underline cursor-pointer">Leverage</h3>

                <div className="">
                  <span>{leverage}x</span>
                </div>
              </div>

              <div className="liquidation flex justify-between text-sm">
                <h3 className="text-slate-500 dotted-underline">Liq.Price</h3>
                <span>-</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default OrderBox
