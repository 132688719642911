export function timeAgo(unixTimestamp) {
  const seconds = Math.floor((Date.now() - unixTimestamp * 1000) / 1000)
  const intervals = [
    { label: "year", duration: 31536000 },
    { label: "month", duration: 2592000 },
    { label: "day", duration: 86400 },
    { label: "hour", duration: 3600 },
    { label: "minute", duration: 60 },
    { label: "second", duration: 1 }
  ]

  for (let i = 0; i < intervals.length; i++) {
    const interval = intervals[i]
    const count = Math.floor(seconds / interval.duration)
    if (count > 1) {
      return `${count} ${interval.label}s ago`
    } else if (count === 1) {
      return `1 ${interval.label} ago`
    }
  }
  return "Just now"
}

export function getTime(unixTimestamp) {
  const numberUnixTimestamp = Number(unixTimestamp)
  const timeFormat = new Date(numberUnixTimestamp)
  let hours = timeFormat.getHours()
  let minutes = timeFormat.getMinutes()
  let seconds = timeFormat.getSeconds()

  hours = (hours < 10 ? "0" : "") + hours
  minutes = (minutes < 10 ? "0" : "") + minutes
  seconds = (seconds < 10 ? "0" : "") + seconds

  return `${hours}:${minutes}:${seconds}`
}
