const { useSubgraphQuery } = require("./useSubgraphQuery")

const composeLatestTradeQuery = ({ marketId }) => {
  return `
    {
      histories(where:{ market_:{ id:"${marketId}"}}) {
        id  
        collateralValue
        collateralAmount
        collateralToken
        createdAt
        isLong
        executedPrice
        market {
          id 
          name
        }
      }
    }
  `
}

/**
 * useLatestTrades.
 *
 * @param {Number} chainId
 * @param {Object.string} marketId
 * @param {Object.string} chainId
 */

const useLatestTrades = ({ chainId, marketId }) => {
  const query = composeLatestTradeQuery({ marketId })
  const data = useSubgraphQuery({ chainId, query, pollingInterval: 3000, autoPolling: true })
  return data ? data.histories : []
}

export default useLatestTrades
