const { useSubgraphQuery } = require("./useSubgraphQuery")

const composeVaultActivitiesQuery = ({ account }) => {
  return `
    {
        vaultTransactions(
          where: {account: "${account}"}
          orderBy: createdAt
          orderDirection: desc
        ) {
          account
          action
          amount
          createdAt
          id
          transactionHash
        }
      }
   `
}

/**
 * useVaultActivities.
 *
 * @param {Number} chainId
 * @param {String} account
 */

const useVaultActivities = (chainId, account) => {
  const query = composeVaultActivitiesQuery({ account })
  const data = useSubgraphQuery({ chainId, query, autoPolling: true, pollingInterval: 3000 })
  return data ? data.vaultTransactions : []
}

export default useVaultActivities
