import { countDigitsAfterDecimal } from "./formatter"

const PriceDisplayDecimals = {
  BTC: 2,
  DOGE: 4,
  APT: 4,
  PEPE: 8,
  SUI: 4
}

const DecimalPercentShow = {
  BTC: 3,
  DOGE: 2,
  ETH: 3,
  MATIC: 2,
  POLYGON: 2,
  SOL: 2,
  PEPE: 2
}

const detectDecimals = (value) => {
  let decimals = 2
  if (value > 1000) {
    decimals = 2
  } else if (value > 1) {
    decimals = 3
  } else if (value > 0.1) {
    decimals = 4
  } else if (value > 0.01) {
    decimals = 6
  } else if (value) {
    const digits = countDigitsAfterDecimal(value)
    decimals = digits
  }
  return decimals
}
export { PriceDisplayDecimals, DecimalPercentShow, detectDecimals }
