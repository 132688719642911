import React, { useMemo } from "react"

import cx from "classnames"

import { Constants } from "@void-0x/void-sdk"
import { useExchangeContext } from "src/contexts/ExchangeContext"
import { useTokenPrice } from "src/hooks/useTokenPriceFeed"
import { formatDollar, formatDollarDecimals, formatPercentage, formatPrice } from "src/lib/formatter"
import { DownIconGreen, DownIconRed } from "@icons/index"
import usePriceInfoBar from "src/hooks/usePriceInfoBar"
import useMarketPrice from "src/hooks/useMarketPrice"
import Countdown from "@components/CountDown/Countdown"
import SelectPairToken from "@components/common/SelectPairToken"
import { useExchangeUIContext } from "src/contexts/ExchangeUIContext"
import { getSymbolFromPairName } from "src/lib/tokens"

const InfoBarChar = () => {
  const { pair, market } = useExchangeContext()

  const { setIsShowHistoryTrade, isShowHistoyTrade, isShowFundingHistory, setIsShowFundingHistory } =
    useExchangeUIContext()

  const { data } = usePriceInfoBar()
  const indexPrice = useTokenPrice(market?.indexToken)
  const { price: marketPrice, status } = useMarketPrice()

  const onChangeShowTradeHistory = () => {
    setIsShowHistoryTrade(!isShowHistoyTrade)
    setIsShowFundingHistory(false)
  }

  const onChangeShowFundingHistory = () => {
    setIsShowFundingHistory(!isShowFundingHistory)
    setIsShowHistoryTrade(false)
  }

  const dataForMapping = useMemo(() => {
    const pairName = getSymbolFromPairName(pair)

    switch (pairName) {
      case "BTC":
        return data?.BTC
      case "ETH":
        return data?.ETH
      case "DOGE":
        return data?.DOGE
      case "PEPE":
        return data?.PEPE
      case "SUI":
        return data?.SUI
      case "UNI":
        return data?.UNI
      case "APT":
        return data?.APT
      default:
        return data?.BTC
    }
  }, [data, pair])

  return (
    <div className="top-chart w-full flex flex-col gap-3 2xl:gap-0 lg:flex-row lg:items-center vh-7">
      <div className="h-full flex justify-between items-center px-3 lg:gap-3">
        <div>
          <SelectPairToken />
        </div>
        <div className="top-chart-price">
          <h3
            className={cx("text-xl font-bold", {
              "text-error": status === -1,
              "text-success": status === 1
            })}
          >
            {marketPrice && marketPrice}
          </h3>
        </div>
      </div>
      <div className="group-infor px-3 py-1 xl:py-0 xl:px-0 flex gap-3 2xl:gap-5 overflow-x-auto">
        <div className="flex flex-col gap-1">
          <h3 className="text-xs text-slate-500">Mark</h3>
          <div className={cx("text-xs mt-auto")}>{marketPrice && marketPrice}</div>
        </div>
        <div className="flex flex-col gap-1">
          <h3 className="text-xs text-slate-500">Index</h3>
          <div className="text-xs mt-auto">
            {indexPrice ? formatPrice(indexPrice, Constants.USD_DECIMALS, false) : ""}
          </div>
        </div>
        <div className="flex justify-center items-center">
          <div className="h-3/5 w-1px bg-slate-700"></div>
        </div>
        <div className="flex flex-col gap-1 max-w-max">
          <h3 className="text-xs text-slate-500 break-normal whitespace-nowrap">24h Change(%)</h3>
          <div className="text-xs mt-auto flex items-center gap-1 text-success">
            <span className={cx({ "text-error": Number(dataForMapping?.priceChangePercent) < 0 })}>
              {dataForMapping && formatPercentage(dataForMapping?.priceChangePercent)}
            </span>
            {dataForMapping && Number(dataForMapping?.priceChangePercent) > 0 && (
              <img src={DownIconGreen} className="w-2 h-2 rotate-180" alt="up" />
            )}
            {dataForMapping && Number(dataForMapping?.priceChangePercent) < 0 && (
              <img src={DownIconRed} className="w-2 h-2" alt="down" />
            )}
          </div>
        </div>
        <div className="flex justify-center items-center">
          <div className="h-3/5 w-1px bg-slate-700"></div>
        </div>
        <div className="flex flex-col gap-1">
          <h3 className="text-xs text-slate-500 whitespace-nowrap">24h Change</h3>
          <div
            className={cx(
              {
                "text-error": Number(dataForMapping?.priceChange) < 0,
                "text-success": Number(dataForMapping?.priceChange) > 0
              },
              "text-xs mt-auto "
            )}
          >
            {dataForMapping && formatDollar(dataForMapping?.priceChange)}
          </div>
        </div>
        <div className="flex justify-center items-center">
          <div className="h-3/5 w-1px bg-slate-700"></div>
        </div>
        <div className="flex flex-col gap-1">
          <h3 className="text-xs text-slate-500">24h High</h3>
          <div className="text-xs mt-auto">
            {dataForMapping && formatDollarDecimals(dataForMapping?.priceHigh24h, 2)}
          </div>
        </div>
        <div className="flex justify-center items-center">
          <div className="h-3/5 w-1px bg-slate-700"></div>
        </div>
        <div className="flex flex-col gap-1">
          <h3 className="text-xs text-slate-500">24h Low</h3>
          <div className="text-xs mt-auto">
            {dataForMapping && formatDollarDecimals(dataForMapping?.priceLow24h, 2)}
          </div>
        </div>
        <div className="flex justify-center items-center">
          <div className="h-3/5 w-1px bg-slate-700"></div>
        </div>
        <div className="flex flex-col gap-1">
          <h3 className="text-xs text-slate-500">Volume</h3>
          <div className="text-xs mt-auto">1,505.660</div>
        </div>
        <div className="flex flex-col gap-1">
          <h3 className="text-xs text-slate-500 whitespace-nowrap">Volume(BTC)</h3>
          <div className="text-xs mt-auto">55.003</div>
        </div>
        <div className="flex justify-center items-center">
          <div className="h-3/5 w-1px bg-slate-700"></div>
        </div>
        <div className="flex flex-col gap-1 max-w-max">
          <h3 className="text-xs text-slate-500 whitespace-nowrap">Next Fund Rate</h3>
          <div className="text-xs mt-auto text-error">-0.013%</div>
        </div>
        <div className="flex justify-center items-center">
          <div className="h-3/5 w-1px bg-slate-700"></div>
        </div>
        <div className="flex flex-col gap-1">
          <h3 className="text-xs text-slate-500">Countdown</h3>
          <div className="text-xs mt-auto">
            <Countdown time="2023-07-18T21:20:33Z" />
          </div>
        </div>
        <div className="flex items-center gap-2">
          <input id="trade-history" type="checkbox" onChange={onChangeShowTradeHistory} checked={isShowHistoyTrade} />
          <h3 htmlFor="trade-history" className="text-sm font-medium text-slate-500 cursor-pointer whitespace-nowrap">
            Latest Trades
          </h3>
        </div>
        <div className="flex items-center gap-2">
          <input
            id="funding-history"
            type="checkbox"
            onChange={onChangeShowFundingHistory}
            checked={isShowFundingHistory}
          />
          <h3 htmlFor="funding-history" className="text-sm font-medium text-slate-500 cursor-pointer whitespace-nowrap">
            Funding History
          </h3>
        </div>
      </div>
    </div>
  )
}

export default InfoBarChar
