import React from "react"

import StandardAsset from "@components/StandardAsset/StandardAsset"
import Banner from "@components/Banner/Banner"
import StatisticInfo from "@components/StatisticInfo/StatisticInfo"
import AboutTradeDex from "@components/AboutTradeDex/AboutTradeDex"
import PageWithHeadAndFoot from "@components/common/PageWithHeadAndFoot/PageWithHeadAndFoot"

const Dashboard = () => {
  return (
    <PageWithHeadAndFoot className="flex flex-col gap-20">
      <Banner />
      <AboutTradeDex />
      <StatisticInfo />
      <StandardAsset />
    </PageWithHeadAndFoot>
  )
}

export default Dashboard
