import { useSubgraphQuery } from "./useSubgraphQuery"

const composePositionQuery = () => {
  return `
  {
     markets(where: { delisted: false }) {
      id
      owner
      name
      category
      marketType
      marketId
      quoteToken
      indexToken
    }
  }
 `
}
/**
 * usePositions.
 *
 * @param {Number} chainId
 * @param {String} account
 */

const useMarkets = (chainId) => {
  const query = composePositionQuery()
  const data = useSubgraphQuery({ chainId, query, autoPolling: false })

  return data ? data.markets : []
}

export default useMarkets
