import WhitepaprPdf from "../../void.pdf"

export const navbar = [
  // {
  //   title: "Dashboard",
  //   link: "%PUBLIC_URL%/void.pdf",
  //   hidden: true
  // },
  {
    title: "Whitepaper",
    link: WhitepaprPdf,
    target: "blank"
  },
  {
    title: "Trade",
    link: "/trade"
  },
  {
    title: "Docs",
    link: "/document"
  },
  {
    title: "Earn",
    link: "/vault/list"
  },
  {
    title: "Profile",
    link: "/profile"
  },
  {
    title: "Faucet",
    link: "/faucet"
  },
  {
    title: "Staking",
    link: "/staking",
    hidden: true
  },
  {
    title: "Market",
    link: "/market",
    bagde: "upcoming"
  }
]
