/* global BigInt */
import { useMemo, useEffect, useState } from "react"
import { useContractRead, useNetwork, usePublicClient } from "wagmi"
import FastPriceFeedABI from "../abis/FastPriceFeed.json"
import { Constants } from "@void-0x/void-sdk"

const useTokenPriceFeed = (tokenAddresses) => {
  const [data, setData] = useState([])

  const publicClient = usePublicClient()
  const { chain } = useNetwork()

  const fetchPrices = async () => {
    try {
      const results = await publicClient.multicall({
        contracts: tokenAddresses.map((tokenAddress) => ({
          address: Constants.Addresses[chain?.id]?.PriceFeed,
          abi: FastPriceFeedABI.abi,
          functionName: "getPrice",
          args: [tokenAddress, true]
        }))
      })

      setData(results)
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    fetchPrices()

    const interval = setInterval(fetchPrices, 5000)

    // Clean up the interval when the component unmounts
    return () => clearInterval(interval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenAddresses])

  const prices = useMemo(() => {
    const result = {}

    if (!data) {
      return result
    }

    data.forEach((item, index) => {
      result[tokenAddresses[index]] = item.result
    })

    return result
  }, [data, tokenAddresses])

  return { prices }
}

export const useTokenPrice = (tokenAddress) => {
  const { chain } = useNetwork()

  const { data: price } = useContractRead({
    address: Constants.Addresses[chain?.id]?.PriceFeed,
    abi: FastPriceFeedABI.abi,
    functionName: "getPrice",
    args: [tokenAddress, true],
    enabled: Boolean(tokenAddress)
  })

  return price || BigInt(0)
}

export default useTokenPriceFeed
