/* global BigInt */

import { formatUnits } from "viem"
import { detectDecimals } from "./decimal"

const defaultOptions = {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
  roundingIncrement: 5
}

export const numberFormatter = new Intl.NumberFormat("en-US", defaultOptions)

export const formatPercentage = (value, decimals = 2) => {
  if (!value) return "-"
  return `${parseFloat(value).toFixed(decimals)}%`
}

/**
 * format value with decimal
 *
 * @param {value} Number
 * @param {decimals} Number
 */

export const formatDollarDecimals = (value, decimals = 2) => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: decimals,
    minimumFractionDigits: decimals
    // roundingIncrement: 5
  })
  if (value !== 0) {
    return formatter.format(value)
  }
  return "$0"
}

/**
 * format BigInt string into human-readable number
 *
 * @param {String} value
 * @param {Number} decimals, default = 18
 * @param {Boolean} withDollarSign
 */
export const formatBigIntString = (value, decimals = 18, withDollarSign = true) => {
  if (!value) {
    return "--"
  }

  return numberFormatter
    .format(formatUnits(BigInt(value), decimals))
    .toString()
    .replace("$", withDollarSign ? "$" : "")
}

/**
 * formatValue: Format a BigInt value to a human readable string
 *
 * @param {bigint} value
 * @param {number} decimals
 * @param {bool} withDollarSign
 */

/**
 * formatValue.
 *
 * @param {} value
 * @param {} decimals
 * @param {} withDollarSign
 */
export const formatValue = (value, decimals, withDollarSign = true) => {
  if (!value) {
    return "--"
  }

  return numberFormatter
    .format(formatUnits(value, decimals))
    .toString()
    .replace("$", withDollarSign ? "$" : "")
}

export const formatDollar = (value) => {
  if (value !== 0) {
    return numberFormatter.format(value)
  }
  return "$0"
}

/**
 * descaleValue: Descale a BigNumber value by 10**decimals
 *
 * @param {bigint} value
 * @param {number} decimals
 * @returns {string}
 */
export const descaleValue = (value, decimals) => {
  return formatUnits(value, decimals)
}

/**
 *
 * @param {Number} num
 */ export const isInt = (num) => {
  if (!isNaN(num)) {
    const parseNum = Number(num)

    if (Number.isInteger(parseNum)) {
      return true
    } else {
      return false
    }
  } else {
    return false
  }
}

/**
 *
 * @param {String} num
 * @param {number} decimals
 */
export const formatDecimals = (num, decimals = 2) => {
  return num === "0" || isNaN(num) ? 0 : isInt(num) ? num : parseFloat(num).toFixed(decimals)
}

/**
 * formatPrice
 *
 * @param {BigInt} value
 * @param {Number} decimals
 * @param {string} formatted price
 */
export const formatPrice = (value, decimals) => {
  const formatted = formatUnits(BigInt(value), decimals)
  const num = Number(formatted)

  const defaultOptions = {
    style: "currency",
    currency: "USD",
    roundingIncrement: 5
  }

  const digits = detectDecimals(num)
  defaultOptions.maximumFractionDigits = digits
  defaultOptions.minimumFractionDigits = digits

  return new Intl.NumberFormat("en-US", defaultOptions).format(formatted)
}

export function numberWithCommas(value) {
  return value ? `${value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}` : value
}

export function countDigitsAfterDecimal(num) {
  const str = num.toString()
  const decimalIndex = str.indexOf(".") // find position of decimal point
  if (decimalIndex === -1) {
    return 0 // if there is no decimal point, return 0
  }
  return str.length - decimalIndex - 1 // subtract decimal position from string length
}
