/* global BigInt */
import React, { useState, useMemo } from "react"
import TableCustom from "@components/Table/TableCustom"
import Button from "@components/Button/Button"

import { useAccount, useNetwork } from "wagmi"
import { Position, Constants } from "@void-0x/void-sdk"

import usePositions from "src/hooks/subgraph/usePositions"
import useTokenPriceFeed from "src/hooks/useTokenPriceFeed"
import { useExchangeContext } from "src/contexts/ExchangeContext"
import useFundingInfo from "src/hooks/subgraph/useFundingInfo"
import useTradingEngine from "src/hooks/useTradingEngine"

import { AddressToSymbolMap, getSymbolFromPairName, MapSymbolToIcon, Tokens } from "src/lib/tokens"
import { formatValue, descaleValue, formatPercentage, formatBigIntString, formatPrice } from "src/lib/formatter"
import cx from "classnames"
import CollateralPopup from "./CollateralPopup"
import { useExchangeUIContext } from "src/contexts/ExchangeUIContext"
import ConfirmCloseModal from "./ConfirmCloseModal"
import { getChecksumAddress } from "src/lib/address"
import Modal from "@components/Modal/Modal"
import { isChainSupported } from "src/lib/chains"
import { timeAgo } from "src/lib/dates"
import { hasKeys } from "src/lib/utils"
import { abs } from "src/lib/math"

const ListPosition = () => {
  const [isOpenedCollatoral, setIsOpenedCollatoral] = useState(false)
  const [isCloseOrdered, setIsCloseOrdered] = useState(false)
  const [isShowFundingModal, setIsShowFundingModal] = useState(false)
  const [isOpenedPnl, setIsOpenedPnl] = useState(false)
  const [dataPnl, setDataPnl] = useState()
  const [fundingModalData, setFundingModalData] = useState({
    position: null,
    market: "",
    type: ""
  })

  const { address } = useAccount()
  const { chain } = useNetwork()
  const { claimFundingPayout, isLoading: isClaiming } = useTradingEngine()

  const { isClosingOrder } = useExchangeContext()
  const { setModalCloseOrderConfirm, setModalCollateralConfirm } = useExchangeUIContext()

  const positions = usePositions(chain?.id, address?.toLowerCase())

  const allIndexTokens = useMemo(() => {
    if (!positions) {
      return []
    }

    // return uniques IndexTokens
    return [...new Set(positions.map((position) => position.market.indexToken))]
  }, [positions])

  const { prices } = useTokenPriceFeed(allIndexTokens)

  const formattedPositions = useMemo(() => {
    if (!isChainSupported(chain) || !prices || !hasKeys(prices)) {
      return []
    }

    const formatteds =
      positions && positions.length > 0
        ? positions?.map((rawPosition, idx) => {
            const position = {
              ...rawPosition,
              isLong: rawPosition.isLong,
              collateralValue: BigInt(rawPosition.collateralValue || 0),
              collateralToken: getChecksumAddress(rawPosition.collateralToken),
              // collateralAmount: BigInt(rawPosition.collateralAmount),
              size: BigInt(rawPosition.size || 0),
              entryPrice: BigInt(rawPosition.entryPrice || 0)
            }

            const indexToken = position.market.indexToken
            const indexPrice = prices[indexToken] || BigInt(0)

            const pnl = Position.getPnl(position.size, position.entryPrice, indexPrice, position.isLong)
            const symbol = getSymbolFromPairName(position.market.name)

            const formattedLiquidationPrice = Position.getLiquidationPrice(
              {
                ...position,
                entryFundingIndex: BigInt(position.entryFundingIndex),
                entryPayoutIndex: BigInt(position.entryPayoutIndex)
              },
              BigInt(position.market.closeFeeRate),
              BigInt(position.market.liquidationFeeRate),
              indexPrice,
              BigInt(position.market.maintenanceMarginBps),
              BigInt(position.market.fundingRatePrecision),
              {
                longPayout: BigInt(position.market.longPayoutIndex || 0),
                shortPayout: BigInt(position.market.shortPayoutIndex || 0),
                longFunding: BigInt(position.market.longFundingIndex || 0),
                shortFunding: BigInt(position.market.shortFundingIndex || 0)
              }
            )

            const { payout: fundingPayout, debt: fundingDebt } = Position.getFunding(
              BigInt(position.entryFundingIndex || 0),
              position.isLong
                ? BigInt(position.market.longFundingIndex || 0)
                : BigInt(position.market.shortFundingIndex || 0),
              BigInt(position.entryPayoutIndex),
              position.isLong
                ? BigInt(position.market.longPayoutIndex || 0)
                : BigInt(position.market.shortPayoutIndex || 0),
              position.size,
              BigInt(position.market.fundingRatePrecision || 0)
            )
            const netPnl = pnl + fundingPayout - fundingDebt
            const fundingPnl = fundingPayout - fundingDebt

            const liquidationPrice = formatPrice(formattedLiquidationPrice, Constants.USD_DECIMALS)
            const netValue = formatValue(netPnl + BigInt(position.collateralValue), Constants.USD_DECIMALS)

            const rawValue = {
              ...position,
              pnl,
              netPnl,
              fundingPnl,
              indexPrice,
              liquidationPrice,
              fundingDebt,
              fundingPayout
            }

            return {
              id: position.id,
              raw: rawValue,
              market: position.market.name,
              collateralValue: formatValue(position.collateralValue, Constants.USD_DECIMALS),
              size: formatValue(position.size, Constants.USD_DECIMALS),
              entryPrice: formatPrice(position.entryPrice, Constants.USD_DECIMALS),
              leverage: Position.getLeverage(position) + "x",
              isProfitable: netPnl > 0,
              indexPrice: formatPrice(indexPrice, Constants.USD_DECIMALS),
              pnlRoe: formatValue(pnl, Constants.USD_DECIMALS),
              type: position.isLong ? "long" : "short",
              token: symbol,
              netValue,
              liquidationPrice,
              icon: MapSymbolToIcon[symbol],
              chainId: chain.id,
              isStart: idx === 0
            }
          })
        : []
    return formatteds
  }, [positions, prices, chain])

  const onConfirmOrder = (cell) => {
    setModalCloseOrderConfirm({ enabled: true, data: { ...cell } })
    setIsCloseOrdered(true)
  }

  const onChangeCollateral = (cell) => {
    setModalCollateralConfirm({ enabled: true, data: { ...cell } })
    setIsOpenedCollatoral(true)
  }

  const onChangeShowFunding = ({ position, market, type }) => {
    setIsShowFundingModal((pre) => !pre)
    setFundingModalData({ position: position, market: market, type: type })
  }

  const fundingInfo = useFundingInfo(chain?.id, { position: fundingModalData.position?.raw })

  const onChangeModalPnl = (data) => {
    setIsOpenedPnl(true)
    setDataPnl(data)
  }

  const columnDef = [
    {
      field: "market",
      headerName: "Market",
      headerClassName: "text-xs text-left pl-3 w-1/12",
      classname: "text-left",
      cellRenderer: (cell) => {
        if (cell) {
          return (
            <div className="flex items-center gap-2">
              <img src={cell?.icon} className="h-6 w-6" alt="eth" />
              <div className="text-left">
                <h3>{cell?.market}</h3>
                <div
                  className={cx("text-xs", {
                    "text-success": cell?.type === "long",
                    "text-error": cell?.type === "short"
                  })}
                >
                  {cell?.type === "long" ? "Long" : "Short"} {cell?.leverage}
                </div>
              </div>
            </div>
          )
        }
        return <div>--</div>
      }
    },
    {
      field: "size",
      headerName: "Size",
      headerClassName: "text-xs w-1/12"
    },
    {
      field: "netValue",
      headerName: "Net Value",
      headerClassName: "text-xs w-1/12"
    },
    {
      field: "collateral",
      headerName: "Collateral",
      headerClassName: "text-xs w-1/12",
      cellRenderer: (cell) => {
        if (cell) {
          const chainId = cell?.chainId
          const collateralToken = getChecksumAddress(cell?.raw?.collateralToken)
          const symbol = AddressToSymbolMap[chainId][collateralToken]
          const token = Tokens[chainId][symbol]

          return (
            <div className="w-full m-auto flex items-center justify-between flex-wrap">
              <div className="flex items-center gap-1 w-3/4">
                <img src={token?.icon} alt="collateraltoken" className="w-6 h-6" />
                <h3 className="truncate">{cell?.collateralValue}</h3>
              </div>
              <Button
                isDefault={false}
                text="+"
                className="bg-slate-800 flex-1"
                onClick={() => onChangeCollateral(cell)}
              />
            </div>
          )
        }
        return <div>--</div>
      }
    },
    {
      field: "entryPrice",
      headerName: "Entry Price",
      headerClassName: "text-xs w-1/12"
    },
    {
      field: "indexPrice",
      headerName: "Index Price",
      headerClassName: "text-xs w-1/12"
    },
    {
      field: "pnlRoe",
      headerName: "Pnl & ROE",
      headerClassName: "text-xs w-1/12",
      cellRenderer: (cell) => {
        if (cell) {
          const rawValue = cell?.raw
          const netPnl = descaleValue(rawValue.netPnl, Constants.USD_DECIMALS)
          const collateralValue = descaleValue(rawValue.collateralValue, Constants.USD_DECIMALS)
          const percentPNL = (parseFloat(netPnl) / parseFloat(collateralValue)) * 100
          return (
            <div
              className="flex flex-col gap-1 cursor-pointer"
              onClick={() =>
                onChangeModalPnl({
                  grossPnl: formatValue(rawValue.pnl, Constants.USD_DECIMALS),
                  fundingDebt: formatValue(rawValue.fundingDebt, Constants.USD_DECIMALS),
                  fundingPayout: formatValue(rawValue.fundingPayout, Constants.USD_DECIMALS),
                  netPnl: formatValue(rawValue.netPnl, Constants.USD_DECIMALS),
                  rawValue: {
                    grossPnl: rawValue.pnl,
                    fundingDebt: rawValue.fundingDebt,
                    fundingPayout: rawValue.fundingPayout,
                    netPnl: rawValue.netPnl
                  }
                })
              }
            >
              <h3 className={cx(cell.isProfitable ? "text-success" : "text-error", "")}>
                {formatValue(rawValue?.netPnl, Constants.USD_DECIMALS)}
              </h3>

              <div className="text-slate-500 font-medium">{formatPercentage(percentPNL) || 0}</div>
            </div>
          )
        }
        return <div>--</div>
      }
    },
    {
      field: "liquidationPrice",
      headerName: "Liquidation Price",
      headerClassName: "text-xs w-1/12"
    },
    {
      field: "feeFunding",
      headerName: "Fees & Funding",
      headerClassName: "text-xs w-1/12",
      cellRenderer: (cell) => {
        return (
          <Button
            text="Show"
            isDefault={false}
            disabled={isClosingOrder}
            className="px-2 py-1 cursor-pointer bg-slate-900 hover:bg-slate-800"
            onClick={() =>
              onChangeShowFunding({
                position: cell,
                market: cell?.market,
                type: cell?.type
              })
            }
          />
        )
      }
    },
    {
      field: "action",
      headerName: "Actions",
      headerClassName: "text-xs w-1/12",
      cellRenderer: (cell) => {
        if (cell) {
          return (
            <Button
              text="Close"
              isDefault={false}
              disabled={isClosingOrder}
              className="px-2 py-1 cursor-pointer bg-slate-900 hover:bg-slate-800"
              onClick={() => onConfirmOrder(cell)}
            />
          )
        }
        return <div>--</div>
      }
    }
  ]

  const pendingFundings = useMemo(() => {
    const fundingHistories = fundingInfo.fundingHistories
    const position = fundingModalData.position

    if (!position || !fundingHistories.length) return []

    const isLong = position?.raw?.isLong
    return fundingHistories.map((funding) => {
      const fundingRate = BigInt(funding.fundingRate)
      const precision = funding.market.fundingRatePrecision
      const isProfitable = isLong ? fundingRate <= 0 : fundingRate > 0
      const percentDecimals = Math.log10(100)
      const fundingRateDecimals = Math.log10(precision)
      const reversedFundingRate = isLong ? fundingRate * BigInt(-1) : fundingRate

      let fundingPnl = BigInt(0)
      // if funding rate > 0, long position is profitable
      // if funding rate < 0, short position is profitable
      if (isProfitable) {
        const longOpenInterest = BigInt(funding.longOpenInterest || 0)
        const shortOpenInterest = BigInt(funding.shortOpenInterest || 0)
        if (isLong) {
          const payoutPerToken = (abs(fundingRate) * shortOpenInterest) / BigInt(precision)
          fundingPnl = longOpenInterest > 0 ? (payoutPerToken * position.raw.size) / longOpenInterest : BigInt(0)
        } else {
          const payoutPerToken = (abs(fundingRate) * longOpenInterest) / BigInt(precision)
          fundingPnl = shortOpenInterest > 0 ? (payoutPerToken * position.raw.size) / shortOpenInterest : BigInt(0)
        }
      } else {
        fundingPnl = (reversedFundingRate * position.raw.size) / BigInt(precision)
      }

      return (
        <div className="grid grid-cols-3 text-slate-500" key={funding.id}>
          <p className="text-left">{timeAgo(funding.timestamp)}</p>
          <p className="text">{descaleValue(fundingRate, fundingRateDecimals - percentDecimals)}%</p>
          <p
            className={cx({
              "text-success": isProfitable,
              "text-error": !isProfitable
            })}
          >
            {formatValue(fundingPnl, Constants.USD_DECIMALS)}
          </p>
        </div>
      )
    })
  }, [fundingModalData.position, fundingInfo.fundingHistories])

  return (
    <>
      <div className="vh-20 w-full overflow-y-auto no-scrollbar">
        {isShowFundingModal && (
          <Modal
            header={
              <div className="flex items-center justify-between">
                <h3>Funding</h3>
                <div className="text-sm flex gap-3 items-center">
                  <p>
                    <span
                      className={cx("uppercase", {
                        "text-success": fundingModalData.type === "long",
                        "text-error": fundingModalData.type === "short"
                      })}
                    >
                      {fundingModalData.type}:
                    </span>{" "}
                    {fundingModalData.market}
                  </p>
                  <p>-</p>
                  <p>
                    Position size: <span className="text-slate-500">{fundingModalData.position?.size}</span>
                  </p>
                </div>
              </div>
            }
            body={
              <div className="text-sm flex flex-col gap-3 max-h-52 overflow-y-auto">
                <div>
                  <h3 className="mb-1 text-left">Paid</h3>
                  <div>
                    <div className="grid grid-cols-3 text-slate-500 border-b mb-1">
                      <p className="text-left">Time</p>
                      <p>Fees</p>
                      <p>Funding paid/received</p>
                    </div>

                    {fundingInfo.feeAndFundings.map((item) => (
                      <div className="grid grid-cols-3 text-slate-500 hover:bg-slate-800 cursor-pointer">
                        <p className="text-left">{timeAgo(item.timestamp)}</p>
                        <p className="text-error">-{formatBigIntString(item.fee)}</p>

                        <div className="flex items-center justify-center gap-1">
                          <span className="text-error">{formatBigIntString(item.fundingDebt)}</span>
                          <span>/</span>
                          <span className="text-success">{formatBigIntString(item.fundingPayout)}</span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div>
                  <p className="text-left mb-1">Pending</p>

                  <div className="grid grid-cols-3 text-slate-500 border-b mb-1">
                    <p className="text-left">Time</p>
                    <p>Funding rate</p>
                    <p>Funding PNL</p>
                  </div>

                  <div>{pendingFundings}</div>
                </div>
              </div>
            }
            footer={
              <div className="grid grid-cols-2 items-center w-full text-sm">
                <div className="flex items-center gap-3">
                  <h3 className="text-left capitalize">Funding PNL:</h3>
                  <p className="text-slate-500">
                    {formatValue(fundingModalData.position?.raw.fundingPnl, Constants.USD_DECIMALS)}
                  </p>
                </div>
                <Button
                  text="Claim"
                  className="w-1/2 ml-auto"
                  isLoading={isClaiming}
                  disabled={fundingModalData.position?.raw.fundingPnl < 0}
                  onClick={async () => {
                    const position = fundingModalData.position?.raw
                    if (!position) {
                      return
                    }

                    await claimFundingPayout({
                      marketId: position?.market?.id,
                      collateralToken: position.collateralToken,
                      isLong: position.isLong
                    })
                  }}
                />
              </div>
            }
            open={isShowFundingModal}
            setOpen={setIsShowFundingModal}
            className="card max-w-xl"
          />
        )}
        {isOpenedPnl && (
          <Modal
            open={isOpenedPnl}
            setOpen={setIsOpenedPnl}
            header={<h3 className=" inline-block mb-2">PNL & ROE</h3>}
            body={
              <div className="flex flex-col gap-2">
                <div className="grid grid-cols-4 border-b pb-2">
                  <p className="text-left">Gross PNL</p>
                  <p>Funding debt</p>
                  <p className="whitespace-nowrap">Funding payout</p>
                  <p>Net PNL</p>
                </div>
                <div className="grid grid-cols-4">
                  <p
                    className={cx("text-left", {
                      "text-success": dataPnl?.rawValue?.grossPnl > BigInt(0),
                      "text-error": dataPnl?.rawValue?.grossPnl < BigInt(0)
                    })}
                  >
                    {dataPnl?.grossPnl}
                  </p>
                  <p className={cx("text-error")}>
                    {dataPnl?.rawValue?.fundingDebt !== BigInt(0) ? "-" + dataPnl?.fundingDebt : "--"}
                  </p>
                  <p className={cx("text-success")}>
                    {dataPnl?.rawValue?.fundingPayout !== BigInt(0) ? dataPnl?.fundingPayout : "--"}
                  </p>
                  <p
                    className={cx({
                      "text-success": dataPnl?.rawValue?.netPnl > BigInt(0),
                      "text-error": dataPnl?.rawValue?.netPnl < BigInt(0)
                    })}
                  >
                    {dataPnl?.netPnl}
                  </p>
                </div>
              </div>
            }
            isBorder={false}
            className="max-w-xl border card text-sm"
          />
        )}
        {isCloseOrdered && (
          <ConfirmCloseModal open={isCloseOrdered} setOpen={setIsCloseOrdered} disabled={isClosingOrder} />
        )}
        {isOpenedCollatoral && <CollateralPopup open={isOpenedCollatoral} setOpen={setIsOpenedCollatoral} />}
        <TableCustom columnDef={columnDef} data={formattedPositions} cellStyle="p-3 text-xs" isBorderHeader={false} />
      </div>
    </>
  )
}

export default ListPosition
