import { createBrowserRouter, RouterProvider } from "react-router-dom"

import { configureChains, createConfig, WagmiConfig } from "wagmi"
import { baseGoerli, arbitrumGoerli } from "wagmi/chains"
import { alchemyProvider } from "wagmi/providers/alchemy"
import { jsonRpcProvider } from "wagmi/providers/jsonRpc"
import { MetaMaskConnector } from "wagmi/connectors/metaMask"
import { CoinbaseWalletConnector } from "wagmi/connectors/coinbaseWallet"

import BgApp from "@components/BgApp/BgApp"
import SEO from "@components/common/SEO"
import { appRoutes } from "./routes"

const baseGoerliExtended = {
  ...baseGoerli,
  contracts: {
    multicall3: {
      address: "0xca11bde05977b3631167028862be2a173976ca11",
      blockCreated: "1376988"
    }
  }
}

// TODO: refactor later
const { chains, publicClient } = configureChains(
  [arbitrumGoerli, baseGoerliExtended],
  [
    jsonRpcProvider({
      rpc: (chain) => {
        if (chain.id === baseGoerli.id) {
          return {
            http: "https://base-goerli.blockpi.network/v1/rpc/public",
            webSocket: "wss://base-goerli.blastapi.io/7732b118-e54f-46ca-a10b-d226670d03dc"
          }
        }
      }
    }),
    // baseGoerliProvider,
    alchemyProvider({ apiKey: "N2823S8lfRKCAyMO9rfYMg0Am36_qZSc" })
  ]
)

const config = createConfig({
  autoConnect: true,
  connectors: [
    new MetaMaskConnector({ chains, options: { name: "MetaMask" } }),
    new CoinbaseWalletConnector({ chains, options: { appName: "Coin Base" } })
  ],
  publicClient
})

const FullApp = () => {
  const router = createBrowserRouter(appRoutes)
  return (
    <div>
      <BgApp />
      <RouterProvider router={router} />
    </div>
  )
}

function App() {
  return (
    <WagmiConfig config={config}>
      <SEO>
        <FullApp />
      </SEO>
    </WagmiConfig>
  )
}

export default App
