import React, { useEffect, useMemo, useState } from "react"
import cx from "classnames"
import { LIST_SECTIONS, optionLabels } from "./constant"
import TopInfo from "@components/TopInfo/TopInfo"
import TradingViewChart from "./TradingViewChart"
import LatestTrade from "./LatestTrade"
import Tab from "@components/Tab/Tab"
import { POSITIONS, ORDERS, TRADES } from "./constant"
import ListPosition from "./ListPosition"
import "./Exchange.css"
import InfoBarChar from "./InfoBarChar"
import SEO from "@components/common/SEO"
import { getPageTitle } from "src/lib/utils"
import { useExchangeContext } from "src/contexts/ExchangeContext"
import Badge from "@components/common/Badge"
import Toast from "@components/common/Toast/Toast"
import { useExchangeUIContext } from "src/contexts/ExchangeUIContext"
import PageWithHeadAndFoot from "@components/common/PageWithHeadAndFoot/PageWithHeadAndFoot"
import TradeHistory from "./TradeHistory"
import OrderBox from "./OrderBox"
import OrderLists from "./OrderLists"
import FundingHistory from "./FundingHistory"
import RequireConnection from "@components/RequireConnection/RequireConnection"
import ErrorModal from "@components/ErrorModal/ErrorModal"

const Exchange = () => {
  const { shouldShowExecutePopup, showErrorModal } = useExchangeContext()
  const { isShowHistoyTrade, isShowFundingHistory, listOrders } = useExchangeUIContext()

  const [tabSection, setTabSection] = useState(LIST_SECTIONS[0])
  const [toastLists, setToastLists] = useState([])

  useEffect(() => {
    if (listOrders && listOrders.length > 0) {
      setToastLists(listOrders)
    }
  }, [listOrders])

  useEffect(() => {
    if (shouldShowExecutePopup) {
      setToastLists([
        ...toastLists,
        {
          id: Math.random(),
          action: "execute"
        }
      ])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldShowExecutePopup])

  const listOrderPopupShow = useMemo(() => {
    const executeList = toastLists.filter((l) => l.action === "execute")
    if (executeList && executeList.length > 0) {
      return executeList.map((e, idx) => ({
        id: idx,
        body: (
          <div className="p-3">
            <h3 className="text-base">Order Executed</h3>
            <p className="text-slate-500 text-sm mt-2">Transaction is executed successfully!</p>
          </div>
        ),
        type: "success"
      }))
    }

    if (toastLists && toastLists.length > 0) {
      return toastLists.map((i) => ({
        id: i.orderId,
        body: (
          <div className="flex flex-col gap-1">
            <div className="flex justify-between">
              <div className="flex items-center gap-2">
                <img src={i?.token} alt="token" className="w-6 h-6" />

                <div className="flex items-center gap-2">
                  <div className="text-sm font-bold capitalize">
                    {i?.action === "open" ? "Place Order" : "Close Order"}
                  </div>
                  <Badge
                    text={i?.side === 0 || i?.side === "long" ? "Long" : "Short"}
                    type={i?.side === 0 || i?.side === "long" ? "long" : "short"}
                  />
                </div>
              </div>
            </div>
            <div className="flex justify-between">
              <div className="text-slate-500">Type</div>
              <div className="text-slate-500 capitalize">{i?.orderType}</div>
            </div>
            <div className="flex justify-between">
              <div className="text-slate-500">Price</div>
              <div>{i?.indexPrice}</div>
            </div>
            <div className="flex justify-between">
              <div className="text-slate-500">Leverage</div>
              <div>{i?.leverage}</div>
            </div>
          </div>
        ),
        type: "success",
        showLoadingLine: true
      }))
    }
  }, [toastLists])

  const onChangeTabSection = (val) => {
    setTabSection(val)
  }

  const renderListSections = () => {
    return (
      <div className={cx("w-full")}>
        <Tab
          optionLabels={optionLabels}
          options={LIST_SECTIONS}
          option={tabSection}
          setOption={onChangeTabSection}
          className={"px-3 pb-1 pt-2"}
        />
        {tabSection === POSITIONS && <ListPosition />}
        {tabSection === ORDERS && <OrderLists />}
        {tabSection === TRADES && <TradeHistory />}
      </div>
    )
  }

  return (
    <SEO title={getPageTitle("Trading")}>
      {showErrorModal?.show && (
        <ErrorModal
          title={showErrorModal.message.name}
          shortMessage={showErrorModal.message.shortMessage}
          contentMessage={showErrorModal.message.message}
        />
      )}
      <PageWithHeadAndFoot isShowFooter={false}>
        {showErrorModal?.show && (
          <ErrorModal
            title={showErrorModal.message.name}
            shortMessage={showErrorModal.message.shortMessage}
            contentMessage={showErrorModal.message.message}
          />
        )}
        <Toast toastList={listOrderPopupShow} position="bottom-right" setToastList={setToastLists} duration={5000} />
        <TopInfo />
        <div className="exchange w-full xl:grid-flow-col xl:grid xl:grid-cols-6 2xl:grid-cols-5 vh-90 border overflow-y-auto no-scrollbar">
          <div className={cx("xl:flex xl:flex-col xl:col-span-4")}>
            <div className="grid grid-cols-1 xl:grid-cols-4 vh-65">
              <div
                className={cx("flex flex-col", {
                  "xl:col-span-3": isShowHistoyTrade && !isShowFundingHistory,
                  "xl:col-span-2": isShowFundingHistory && !isShowHistoyTrade,
                  "xl:col-span-4": !isShowHistoyTrade && !isShowFundingHistory
                })}
              >
                <div className="relative">
                  <InfoBarChar />
                </div>
                <div className="flex-1">
                  <RequireConnection>
                    <TradingViewChart />
                  </RequireConnection>
                </div>
              </div>
              {isShowHistoyTrade && !isShowFundingHistory && (
                <div className="xl:col-span-1 border-l">
                  <LatestTrade />
                </div>
              )}
              {isShowFundingHistory && !isShowHistoyTrade && (
                <div className="xl:col-span-2 border-l">
                  <FundingHistory />
                </div>
              )}
            </div>

            {/* position */}
            <div className={cx("vh-25")}>{renderListSections()}</div>
          </div>
          {/* order box */}
          <div className="xl:col-span-2 2xl:col-span-1 vh-90 border">
            <OrderBox />
          </div>
        </div>
      </PageWithHeadAndFoot>
    </SEO>
  )
}

export default Exchange
