import React, { useEffect, useState } from "react"
import cx from "classnames"
import "./LoadingLine.css"

const LoadingLine = ({ type = "default" }) => {
  const [loading, setLoading] = useState(0)
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading((prevWidth) => prevWidth + 5)
    }, 200)

    if (loading > 95) {
      clearTimeout(timer)
    }

    return () => clearTimeout(timer)
  }, [loading, setLoading])

  return (
    <div>
      <div
        style={{
          width: `${loading}%`,
          transition: "all 0.5s ease-in-out"
        }}
        className={cx("rounded h-1", {
          "bg-green": type === "success",
          "bg-red": type === "error",
          "bg-yellow": type === "pending",
          "bg-default": type === "default"
        })}
      />
    </div>
  )
}

export default LoadingLine
