import { isNonEmptyObject } from "src/lib/utils"
import { useSubgraphQuery } from "./useSubgraphQuery"

const composeFundingHistoriesQuery = (position) => {
  return position
    ? `
        fundingHistories(where: {and: [{market: "${position.market.id}"}, {timestamp_gte: ${position.updatedAt}}]}, first: 10, orderBy: timestamp, orderDirection: desc) {
          fundingRate
          id
          longOpenInterest
          shortOpenInterest
          timestamp
          longPayoutIndex
          shortPayoutIndex
          nInterval
          market {
           fundingRatePrecision
          }
        }
  `
    : ""
}

const composeFundingInfoQuery = (position) => {
  return position
    ? `
    {
        
        feeAndFundings(where: {and: [{position: "${position.id}"}, {timestamp_gte: ${
        position.createdAt
      }}]}, orderBy: timestamp, orderDirection: desc) {
          fee
          fundingDebt
          fundingPayout
          timestamp
        }

        ${composeFundingHistoriesQuery(position)}
    }
  `
    : ""
}

/**
 * useOrders.
 *
 * @param {Number} chainId
 * @param {Object} object
 * @param {Object.string} account
 */

const useFundingInfo = (chainId, { position }) => {
  const query = composeFundingInfoQuery(position)
  const data = useSubgraphQuery({ chainId, query })
  return isNonEmptyObject(data)
    ? {
        fundingHistories: data.fundingHistories,
        feeAndFundings: data.feeAndFundings
      }
    : {
        fundingHistories: [],
        feeAndFundings: []
      }
}

export default useFundingInfo
