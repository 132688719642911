import Button from "@components/Button/Button"
import Modal from "@components/Modal/Modal"
import React, { useMemo } from "react"
import { useExchangeUIContext } from "src/contexts/ExchangeUIContext"
import { formatDollar } from "src/lib/formatter"
import cx from "classnames"

const generateOrderType = {
  0: "Market",
  1: "Limit",
  2: "Stop Market"
  // 3: ''
}

const ConfirmPlaceOrderModal = ({ disabled, open, setOpen, onPlaceOrder, isPlacingOrder }) => {
  const { modalOrderComfirm } = useExchangeUIContext()

  const headerConfirmOrder = useMemo(() => {
    return (
      <div className="flex items-center gap-1">
        <h2>Confirm Make Order -</h2>
        <div
          className={cx({
            "text-success": modalOrderComfirm?.data?.side === 0,
            "text-error": modalOrderComfirm?.data?.side === 1
          })}
        >
          {modalOrderComfirm?.data?.side === 0 ? "Long" : "Short"} {modalOrderComfirm?.data?.leverage}x
        </div>
      </div>
    )
  }, [modalOrderComfirm])

  const bodyConfirmOrder = useMemo(() => {
    return (
      modalOrderComfirm?.enabled && (
        <div className="flex flex-col gap-5">
          <div className="grid grid-cols-2 gap-3">
            <div className="border py-2 px-2 rounded text-left">
              <h5 className="text-slate-500 text-sm">Price</h5>
              <div className="text-sm">{modalOrderComfirm?.data?.indexPrice}</div>
            </div>
            <div className="border py-2 px-2 rounded text-left">
              <h5 className="text-slate-500 text-sm">Order Type</h5>
              <div className="text-sm">{generateOrderType[modalOrderComfirm?.data?.orderType]}</div>
            </div>
          </div>
          <div className="border p-2 rounded">
            <h3 className="text-sm text-slate-500 text-left">Pay Amount</h3>
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-1">
                <span>{modalOrderComfirm?.data?.payAmount}</span>
              </div>
              <div className="flex items-center gap-1">
                <img src={modalOrderComfirm?.data?.purchaseIcon} alt="token" className="w-4 h-4" />{" "}
                <span className="text-sm">{modalOrderComfirm?.data?.purchaseLabel}</span>
              </div>
            </div>
          </div>
          <div className="w-full h-1 bg-slate-800"></div>
          <div className="flex flex-col gap-1">
            <div className="flex items-center justify-between text-sm">
              <h3 className=" text-slate-500 dotted-underline">Leverage</h3>
              <div>{modalOrderComfirm?.data?.leverage}x</div>
            </div>
            <div className="flex items-center justify-between text-sm">
              <h3 className="text-slate-500 dotted-underline">Position Size</h3>
              <div>{formatDollar(modalOrderComfirm?.data?.positionSize)}</div>
            </div>
          </div>
        </div>
      )
    )
  }, [modalOrderComfirm])

  const footerConfirmOrder = useMemo(() => {
    return (
      <Button
        text="Place Order"
        onClick={onPlaceOrder}
        isLoading={isPlacingOrder}
        isDefault={false}
        disabled={isPlacingOrder}
        className={cx("cursor-pointer py-2", {
          "bg-green": modalOrderComfirm?.data?.side === 0,
          "bg-red": modalOrderComfirm?.data?.side === 1
        })}
      />
    )
  }, [isPlacingOrder, modalOrderComfirm?.data?.side, onPlaceOrder])

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      disabled={disabled}
      header={headerConfirmOrder}
      body={bodyConfirmOrder}
      footer={footerConfirmOrder}
    />
  )
}

export default ConfirmPlaceOrderModal
