import { useSubgraphQuery } from "./useSubgraphQuery"

const composeFundingHistoiesQuery = ({ marketId }) => {
  return `
    {
        fundingHistories(where: {market: "${marketId}"},first: 10, orderBy: timestamp, orderDirection: desc) {
          fundingRate
          id
          longOpenInterest
          shortOpenInterest
          timestamp
          longPayoutIndex
          shortPayoutIndex
          nInterval
          market {
           fundingRatePrecision
          }
        }
      }
  `
}

/**
 * useOrders.
 *
 * @param {Number} chainId
 * @param {Object} object
 * @param {Object.string} account
 */

const useFundingHistoies = ({ chainId, marketId }) => {
  const query = composeFundingHistoiesQuery({ marketId })
  const data = useSubgraphQuery({ chainId, query })
  return data ? data.fundingHistories : []
}

export default useFundingHistoies
