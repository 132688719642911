import { useContext, useState, createContext, useMemo, useEffect } from "react"
import { usePublicClient, useWalletClient, useNetwork } from "wagmi"
import { Exchange, Constants } from "@void-0x/void-sdk"
import { isChainSupported } from "src/lib/chains"
import { DefaultMarkets } from "src/lib/market"

const ExchangeContext = createContext()

export function ExchangeContextProvider({ children }) {
  const [isPlacingOrder, setIsPlacingOrder] = useState(false)
  const [isCancelOrder, setIsCancelOrder] = useState(false)
  const [isClosingOrder, setIsClosingOrder] = useState(false)
  const [showErrorModal, setShowErrorModal] = useState({ show: false, message: null })
  const [side, setSide] = useState(0)
  const [isError, setIsError] = useState(false)
  const [isSuccess, setIsSuccess] = useState(null)

  // index token
  const [indexToken, setIndexToken] = useState()
  // pair is the combination of base/quote
  // E.g: BTC/USD
  const [pair, setPair] = useState("BTC")
  const [market, setMarket] = useState({})

  const publicClient = usePublicClient()
  const { data: walletClient } = useWalletClient()
  const { chain } = useNetwork()

  const exchange = useMemo(() => {
    if (chain && isChainSupported(chain)) {
      return new Exchange(publicClient, walletClient, Constants.Addresses[chain.id].Exchange)
    }
  }, [publicClient, walletClient, chain])

  // useContractEvent({
  //   address: Constants.Addresses[chain?.id]?.Exchange,
  //   abi: Exchange.getABI(),
  //   eventName: "OrderExecuted",
  //   listener(log) {
  //     setShouldRefreshPositions(true)
  //     const orderId = log[0].args.orderId

  //     const alreadyOrderLists = orderListsRef.current

  //     if (alreadyOrderLists && alreadyOrderLists.length > 0 && orderId) {
  //       setOrderLists([...alreadyOrderLists.filter((o) => o.orderId !== Number(orderId))])
  //       setShouldShowExecutePopup(true)
  //     } else {
  //       setShouldShowExecutePopup(true)
  //     }

  //     setTimeout(() => {
  //       setShouldShowExecutePopup(false)
  //     }, 5000)
  //   }
  // })

  /**
   * Set default indexToken and pair for the market
   */
  useEffect(() => {
    if (chain) {
      setMarket(DefaultMarkets[chain?.id])
    }
  }, [chain])

  const placeOrder = async (params) => {
    if (!exchange) {
      return
    }

    try {
      setIsPlacingOrder(true)
      await exchange.placeOrder(params, { simulate: true })
      setIsPlacingOrder(false)
      setIsSuccess(true)
    } catch (error) {
      setIsError(true)
      setIsSuccess(false)
      setIsPlacingOrder(false)
      setShowErrorModal({ show: true, message: error })
      setTimeout(() => {
        setShowErrorModal({ show: false, message: null })
        setIsError(false)
      }, 5000)
    }
  }

  const closeOrder = async (params) => {
    if (!exchange) {
      return
    }

    try {
      setIsClosingOrder(true)
      console.log("close order params", params)
      await exchange.closeOrder(params)

      setIsClosingOrder(false)
    } catch (error) {
      setIsError(true)
      setShowErrorModal({ show: true, message: error })
      setTimeout(() => {
        setIsError(false)
        setShowErrorModal({ show: false, message: null })
        setIsClosingOrder(false)
      }, 5000)
    }
  }

  const cancelOrder = async (orderId) => {
    if (!exchange) {
      return
    }

    try {
      setIsCancelOrder(true)
      await exchange.cancelOrder(orderId, {
        simulate: true
      })
      setIsCancelOrder(false)
    } catch (error) {
      setIsCancelOrder(false)
      setShowErrorModal({ show: true, message: error })
    }
  }

  return (
    <ExchangeContext.Provider
      value={{
        indexToken,
        setIndexToken,
        pair,
        setPair,
        market,
        setMarket,
        isPlacingOrder,
        placeOrder,
        cancelOrder,
        isClosingOrder,
        closeOrder,
        showErrorModal,
        side,
        setSide,
        isCancelOrder,
        isSuccess,
        isError
      }}
    >
      {children}
    </ExchangeContext.Provider>
  )
}
/**
 * The Exchange context object.
 * @typedef {Object} ExchangeContextType
 * @property {string} indexToken - The index token.
 * @property {Array} orderLists - Array list.
 * @property {function} setIndexToken - The function to set the index token.
 * @property {string} side - The side for long or short
 * @property {function} setSide - The function to set the side order.
 * @property {string} pair - The combination of base/quote. Eg: BTC/USD
 * @property {function} setPair - The function to set the pair.
 * @property {function} setMarket - Set current market
 * @property {function} cancelOrder - cancel an order
 * @property {boolean} isPlacingOrder - A boolean indicating if an order is being placed.
 * @property {Object} showErrorModal - User reject transaction
 * @property {function} placeOrder - The function to place an order.
 * @property {boolean} isClosingOrder - A boolean indicating if an order is being closed.
 * @property {function} closeOrder - The function to close an order.
 * @property {function} market - A variable show market pair .
 * @property {function} isCancelOrder - A Boolean loading cancel order.
 * @property {function} isError - A Boolean occure when reject or error action place order.
 * @property {function} isSuccess - A Boolean occure when success action place order.
 */

/**
 * A hook to return the Exchange context object.
 * @function useExchangeContext
 * @returns {ExchangeContextType} - The Exchange context object.
 * @throws {Error} - Throws if context is undefined.
 */

export function useExchangeContext() {
  const context = useContext(ExchangeContext)
  if (context === undefined) {
    throw new Error("Context must be used within a Provider")
  }
  return context
}
