/* global BigInt */
import TableCustom from "@components/Table/TableCustom"
import { CancelDefaultCircle, SearchIcon } from "@icons/index"
import { Constants } from "@void-0x/void-sdk"
import React from "react"
import { useExchangeUIContext } from "src/contexts/ExchangeUIContext"
import useFundingHistoies from "src/hooks/subgraph/useFundingHistories"
import { timeAgo } from "src/lib/dates"
import { formatValue, descaleValue, formatPercentage } from "src/lib/formatter"
import { useNetwork } from "wagmi"
import { abs } from "src/lib/math"
import { useExchangeContext } from "src/contexts/ExchangeContext"
import { InputCustom } from "@components/common"

const FundingHistory = () => {
  const { onChangeCloseFundingAndHistory } = useExchangeUIContext()
  const { market } = useExchangeContext()

  const { chain } = useNetwork()
  const fundingHistories = useFundingHistoies({ chainId: chain?.id, marketId: market?.id })

  const columnDef = [
    {
      field: "timestamp",
      headerName: "Time",
      headerClassName: "text-sm text-left",
      className: "text-left",
      cellRenderer: (cell) => {
        return <p>{timeAgo(cell?.timestamp)}</p>
      }
    },
    {
      field: "longOpenInterest",
      headerName: "Long Open Interest",
      headerClassName: "text-sm",
      formatter: (val) => {
        return val?.longOpenInterest ? formatValue(BigInt(val?.longOpenInterest), Constants.USD_DECIMALS) : "--"
      }
    },
    {
      field: "shortOpenInterest",
      headerName: "Short Open Interest",
      headerClassName: "text-sm",
      formatter: (val) => {
        return val?.shortOpenInterest ? formatValue(BigInt(val?.shortOpenInterest), Constants.USD_DECIMALS) : "--"
      }
    },
    {
      field: "",
      headerName: "Skew",
      headerClassName: "text-sm",
      cellRenderer: (cell) => {
        const longOpenInterest = Number(cell?.longOpenInterest)
        const shortOpenInterest = Number(cell?.shortOpenInterest)
        const sum = longOpenInterest + shortOpenInterest
        const longSkew = (longOpenInterest / sum) * 100
        const shortSkew = (shortOpenInterest / sum) * 100

        return (
          <div className="flex items-center justify-center gap-1">
            <span className="text-success">{formatPercentage(longSkew, 0) || "--"}</span>
            <span>/</span>
            <span className="text-error">{formatPercentage(shortSkew, 0) || "--"}</span>
          </div>
        )
      }
    },
    {
      field: "fundingRate",
      headerName: "Funding Rate",
      headerClassName: "text-sm",
      formatter: (val) => {
        const decimals = Math.log10(val?.market?.fundingRatePrecision || 0)
        const percentPrecision = Math.log10(100)
        const rate = descaleValue(BigInt(val?.fundingRate || 0), decimals - percentPrecision)
        return `${rate}%`
      }
    },
    {
      field: "longPayoutIndex",
      headerName: "Long Payout",
      headerClassName: "text-sm",
      cellRenderer: (val) => {
        if (val?.fundingRate && BigInt(val.fundingRate) < 0) {
          const longPayout =
            (BigInt(val?.shortOpenInterest) / BigInt(val?.market.fundingRatePrecision)) * BigInt(val?.fundingRate)

          return formatValue(abs(longPayout), Constants.USD_DECIMALS)
        }

        return "--"
      }
    },
    {
      field: "shortPayoutIndex",
      headerName: "Short Payout",
      headerClassName: "text-sm",
      cellRenderer: (val) => {
        if (val?.fundingRate && BigInt(val.fundingRate) > 0) {
          const shortPayout =
            (BigInt(val?.longOpenInterest) / BigInt(val?.market.fundingRatePrecision)) * BigInt(val?.fundingRate)

          return formatValue(shortPayout, Constants.USD_DECIMALS)
        }

        return "--"
      }
    }
  ]

  return (
    <div className="w-full p-3">
      <div className="mb-3">
        <div className="flex justify-between">
          <div className="flex items-center gap-3">
            <h3 className="text-sm capitalize">Funding Rate Histories</h3>
            <div className="h-8">
              <InputCustom
                classNameInput="py-0 px-1"
                type="string"
                placeHolder="Search"
                leftSide={<img src={SearchIcon} className="w4 h-4" alt="search" />}
              />
            </div>
          </div>
          <img
            src={CancelDefaultCircle}
            alt="right-left"
            className="w-6 h-6 cursor-pointer"
            onClick={onChangeCloseFundingAndHistory}
          />
        </div>
      </div>
      <TableCustom
        columnDef={columnDef}
        data={fundingHistories || []}
        cellStyle="py-3 text-xs"
        rowStyle="cursor-pointer mb-2"
      />
    </div>
  )
}

export default FundingHistory
