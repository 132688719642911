import React, { useMemo } from "react"
import { DownIconGreen, DownIconRed } from "@icons/index"
import Card from "@components/Card/Card"
import TableCustom from "@components/Table/TableCustom"
import cx from "classnames"
import usePriceList from "src/hooks/usePriceList"
import { formatDollarDecimals, formatPercentage } from "src/lib/formatter"
import { DecimalPercentShow, PriceDisplayDecimals } from "src/lib/decimal"
import { MapSymbolToIcon } from "src/lib/tokens"

const columnDef = [
  {
    headerName: "Asset",
    headerClassName: "text-left w-1/5",
    cellRenderer: (cell) => {
      return (
        <div className="flex gap-2 items-center">
          <img src={MapSymbolToIcon[cell?.symbol]} alt={cell?.symbol} className="w-10 h-10 border rounded-full" />
          <div className="text-left">
            <h3>{cell?.symbol}</h3>
            <div className="text-slate-400 text-sm">{cell?.label}</div>
          </div>
        </div>
      )
    }
  },
  {
    field: "price",
    headerName: "Price",
    headerClassName: "w-1/5",
    formatter: (cell) => {
      return formatDollarDecimals(cell?.price, PriceDisplayDecimals[cell?.symbol])
    }
  },
  {
    field: "percentChange1h",
    headerName: "1h Change",
    headerClassName: "w-1/5",
    cellRenderer: (cell) => {
      return (
        <div className={cx({ "text-success": cell?.percentChange1h > 0, "text-error": cell?.percentChange1h < 0 })}>
          {formatPercentage(cell?.percentChange1h, DecimalPercentShow[cell?.symbol])}
        </div>
      )
    },
    sortable: true
    // sortbyOrder: "desc"
  },
  {
    field: "percentChange24h",
    headerName: "24h Change",
    headerClassName: "w-1/5",
    cellRenderer: (cell) => {
      return (
        <div className="flex flex-col ">
          <div className="flex gap-2 justify-center">
            <img
              src={cell?.percentChange24h > 0 ? DownIconGreen : DownIconRed}
              alt="down"
              className={cx({
                rotate180: cell?.percentChange24h > 0
              })}
            />
            <div
              className={cx({
                "text-error": cell?.percentChange24h < 0,
                "text-success": cell?.percentChange24h > 0
              })}
            >
              {formatPercentage(cell?.percentChange24h)}
            </div>
          </div>
        </div>
      )
    }
  },
  {
    field: "volume24h",
    headerName: "24h Volume",
    headerClassName: "text-right w-1/5",
    className: "text-right",
    formatter: (cell) => {
      return formatDollarDecimals(Number(cell?.volume24h), 2)
    }
  }
]

const StandardAsset = () => {
  const { data } = usePriceList()

  const dataForTable = useMemo(() => {
    if (data) {
      return data.map((item) => ({
        id: item?.label,
        symbol: item?.symbol,
        label: item?.label,
        price: item?.price,
        percentChange1h: item?.percentChange1h,
        percentChange24h: item?.percentChange24h,
        volume24h: item?.volume24h
      }))
    }
    return []
  }, [data])

  return (
    <div className="px-10 2xl:p-0 ">
      <Card className="mt-10 container mx-auto max-w-7xl px-5 p-5" hasShadow={true}>
        <h3 className="font-medium text-lg">Standard Assets</h3>
        <TableCustom
          columnDef={columnDef}
          data={dataForTable}
          cellStyle="py-2"
          rowStyle="cursor-pointer hover:bg-gray-900 rounded"
          requireConnect={false}
        />
      </Card>
    </div>
  )
}

export default StandardAsset
