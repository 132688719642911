import React, { useState, useMemo, useCallback } from "react"

import cx from "classnames"

import { useExchangeContext } from "src/contexts/ExchangeContext"
import usePriceInfoBar from "src/hooks/usePriceInfoBar"
import useOutsideDetect from "../../hooks/useOutsideDetect"

import { formatPercentage } from "src/lib/formatter"

import { AscIcon, DescIcon, DownIcon, SearchIcon, StarIcon, StarIconYellow } from "@icons/index"

import InputCustom from "./InputCustom"
import useLocalStorage from "src/hooks/useLocalStorage"
import { MapSymbolToIcon, TokenName, getSymbolFromPairName } from "src/lib/tokens"
import { useNetwork } from "wagmi"

import useMarkets from "src/hooks/subgraph/useMarkets"
import "./SelectPairToken.css"
import { getChecksumAddress } from "src/lib/address"

const lastPriceFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",

  maximumFractionDigits: 10,
  minimumFractionDigits: 1
})

const SelectPairToken = ({ defaultValue = "BTC/USD" }) => {
  const [getFavouritePairs, setFavouritePairs] = useLocalStorage("favourite.pair")
  const [values, setValues] = useState(defaultValue)
  const [recommendKey, setRecommentKey] = useState("all")
  const [searchKey, setSearchKey] = useState()
  const [isOrdered, setIsOrdered] = useState(false)
  const [isOpenList, setIsOpenList] = useState(false)

  const { chain } = useNetwork()
  const { setPair, setMarket } = useExchangeContext()
  const { data: listPrice } = usePriceInfoBar()
  const favouriteLists = getFavouritePairs()

  const toggleOrder = () => setIsOrdered((prev) => !prev)

  const markets = useMarkets(chain?.id)

  const listPairs = useMemo(() => {
    if (listPrice) {
      return markets && markets.length > 0
        ? markets
            .sort((a, b) => {
              const x = a.name.toLowerCase()
              const y = b.name.toLowerCase()
              return !isOrdered ? x.localeCompare(y) : y.localeCompare(x)
            })
            .map((market) => {
              const symbol = getSymbolFromPairName(market.name)

              return {
                id: market.id,
                label: market.name,
                value: market.name,
                marketType: market.marketType,
                indexToken: market.indexToken,
                quoteToken: market.quoteToken,
                tokenName: TokenName[symbol],
                icon: MapSymbolToIcon[symbol],
                price: listPrice?.[symbol]?.lastPrice,
                dayChange: listPrice?.[symbol]?.priceChangePercent,
                isFavourite: favouriteLists && favouriteLists.some((f) => f === market.id)
              }
            })
        : []
    }
    return []
  }, [favouriteLists, listPrice, isOrdered, markets])

  const listAllPairs = useMemo(() => {
    // delete listPrice?.LINK

    if (listPrice && recommendKey === "all") {
      if (searchKey) {
        return listPairs.filter((f) => f.tokenName.toLowerCase().includes(searchKey))
      }
      return listPairs
    }
    return []
  }, [listPairs, listPrice, recommendKey, searchKey])

  const listFavouritePairs = useMemo(() => {
    if (listPairs && searchKey) {
      return listPairs.filter((f) => f.isFavourite === true && f.tokenName.toLowerCase().includes(searchKey))
    }
    return listPairs.filter((f) => f.isFavourite === true)
  }, [listPairs, searchKey])

  const listPairsToRender = useMemo(() => {
    switch (recommendKey) {
      case "all":
        return listAllPairs
      case "favourite":
        return listFavouritePairs
      default:
        return []
    }
  }, [listAllPairs, listFavouritePairs, recommendKey])

  const toggleOpen = () => {
    setIsOpenList(!isOpenList)
  }

  const onChangeValue = (market) => {
    setValues(market.value)
    setPair(market.value)
    setMarket({
      id: market.id,
      indexToken: getChecksumAddress(market.indexToken),
      quoteToken: getChecksumAddress(market.quoteToken),
      marketType: market.marketType,
      name: market.label
    })
    setIsOpenList(false)
  }

  const onChangeSearchKey = (value) => {
    setSearchKey(value)
  }

  const handleSavefavourite = useCallback(
    (id) => {
      if (favouriteLists && id && favouriteLists.some((i) => i === id)) {
        setFavouritePairs([...favouriteLists.filter((i) => i !== id)])
      } else if (favouriteLists) {
        setFavouritePairs([...favouriteLists, id])
      } else {
        setFavouritePairs([id])
      }
    },
    [favouriteLists, setFavouritePairs]
  )

  const handleClickOutside = () => {
    setIsOpenList(false)
  }

  const refOutside = useOutsideDetect(handleClickOutside)

  const { label, icon } = useMemo(() => {
    const index = listPairs.findIndex((item) => item.value === values)
    return { label: listPairs[index]?.label, icon: listPairs[index]?.icon }
  }, [listPairs, values])

  return (
    <div className="pair-token" ref={refOutside}>
      <div className="flex gap-2 items-center text-white cursor-pointer" onClick={toggleOpen}>
        <div className="w-7 h-7">{icon && <img src={icon} alt="btc" className="rounded-full w-full h-full" />}</div>
        <h3 className="cursor-pointer text-lg label font-bold text-ellipsis overflow-hidden">{label}</h3>
        <img src={DownIcon} className={`${isOpenList ? "rotate180" : ""} w-3`} alt="downicon" />
      </div>
      <div
        className={cx(
          { open: isOpenList, close: !isOpenList },
          "list card overflow-x-auto flex flex-col gap-3 rounded py-5 shadow"
        )}
      >
        <h3 className="pl-5 font-medium">Select Market</h3>
        <div className="search px-5">
          <InputCustom
            classNameInput="py-2 px-1"
            placeHolder="Search Asset"
            leftSide={<img src={SearchIcon} alt="search" className="w-4 h-4" />}
            onChange={onChangeSearchKey}
            type="string"
          />
        </div>
        <div className="flex items-center justify-between pr-5">
          <div className="recommend pl-5 flex items-center gap-3 col-span-2">
            <span
              className={cx("text-sm border px-2 py-1 rounded cursor-pointer", {
                "active font-medium": recommendKey === "all"
              })}
              onClick={() => setRecommentKey("all")}
            >
              All
            </span>
            <span
              className={cx("text-sm border px-2 py-1 rounded cursor-pointer", {
                "active font-medium": recommendKey === "favourite",
                disabled: listFavouritePairs?.length === 0
              })}
              onClick={() => setRecommentKey("favourite")}
            >
              Favourite ({listFavouritePairs?.length})
            </span>
          </div>
          <div className="cursor-pointer" onClick={toggleOrder}>
            {isOrdered ? (
              <img src={AscIcon} alt="bar" className="w-5 h-5" />
            ) : (
              <img src={DescIcon} alt="bar" className="w-5 h-5" />
            )}
          </div>
        </div>

        <div className="px-5">
          <div className="w-full bg-line h-1px"></div>
        </div>
        <table className="w-full text-sm text-left">
          <thead className="text-xs">
            <tr>
              <th className="px-5 py-1 font-normal whitespace-nowrap"></th>
              <th className="px-5 py-1 font-normal whitespace-nowrap">Pair</th>
              <th className="px-5 py-1 font-normal whitespace-nowrap">Last price</th>
              <th className="px-5 py-1 font-normal whitespace-nowrap">24h Change</th>
            </tr>
          </thead>
          <tbody>
            {listPairsToRender?.map((item) => (
              <tr className="pair-token-list hover:bg-slate-900" key={item.id}>
                <th className="pl-5">
                  {item?.isFavourite ? (
                    <img
                      src={StarIconYellow}
                      alt="star"
                      className="w-4 h-4 cursor-pointer"
                      onClick={() => handleSavefavourite(item.id)}
                    />
                  ) : (
                    <img
                      src={StarIcon}
                      alt="star"
                      className="w-4 h-4 cursor-pointer"
                      onClick={() => handleSavefavourite(item.id)}
                    />
                  )}
                </th>
                <td
                  className="pl-2 pr-12 py-2 font-medium whitespace-nowrap flex items-center cursor-pointer"
                  onClick={() => onChangeValue(item)}
                >
                  <img src={item.icon} alt="icon" className="rounded-full w-6 h-6 mr-1" />
                  <div className="flex flex-col">
                    <h3 className="text-sm">{item.label}</h3>
                    <span className="text-slate-500 text-xs">{item?.tokenName}</span>
                  </div>
                </td>
                <td
                  className={cx("pl-5 pr-12 py-2", {
                    "text-success": Number(item.dayChange) > 0,
                    "text-error": Number(item.dayChange) < 0
                  })}
                >
                  {lastPriceFormatter.format(item.price)}
                </td>
                <td
                  className={cx("pl-5 pr-12 py-2", {
                    "text-success": Number(item.dayChange) > 0,
                    "text-error": Number(item.dayChange) < 0
                  })}
                >
                  {formatPercentage(item.dayChange)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default SelectPairToken
