/* global BigInt */

import React, { useCallback, useMemo, useState } from "react"
import cx from "classnames"
import { parseUnits } from "viem"

import { useExchangeUIContext } from "src/contexts/ExchangeUIContext"
import { useAccount, useBalance } from "wagmi"
import { formatDecimals, formatValue } from "src/lib/formatter"
import { useTokenPrice } from "src/hooks/useTokenPriceFeed"
import { Constants } from "@void-0x/void-sdk"

import Button from "@components/Button/Button"
import Modal from "@components/Modal/Modal"
import { ArrowLongRightIcon } from "@icons/index"

const CollateralPopup = ({ open, setOpen }) => {
  const { modalCollateralConfirm } = useExchangeUIContext()

  const [collateralTab, setCollateralTab] = useState("add")
  const [collateralAmount, setCollateralAmount] = useState()

  const { address } = useAccount()

  const { data: balance } = useBalance({
    address: address,
    token: modalCollateralConfirm?.data?.raw?.collateralToken,
    watch: true,
    staleTime: 2_000
  })

  const price = useTokenPrice(modalCollateralConfirm?.data?.raw?.collateralToken)

  const calculateCollateral = useCallback(
    (percent, valueToCal = BigInt(0)) => {
      return modalCollateralConfirm.enabled ? (valueToCal * BigInt(percent)) / BigInt(100) : BigInt(0)
    },
    [modalCollateralConfirm.enabled]
  )

  const collateralValueToShow = useMemo(() => {
    if (modalCollateralConfirm?.enabled && collateralAmount && collateralTab === "remove") {
      const valueDecimals = balance?.decimals + Constants.ORACLE_PRICE_DECIMALS
      return formatValue(collateralAmount, valueDecimals)
    }
    if (modalCollateralConfirm?.enabled && collateralAmount && collateralTab === "add") {
      const valueDecimals = balance?.decimals + Constants.ORACLE_PRICE_DECIMALS
      return formatValue(collateralAmount, valueDecimals)
    }

    return "$0.00"
  }, [balance?.decimals, collateralAmount, collateralTab, modalCollateralConfirm])

  const tokenMaxValueInUSD = useMemo(() => {
    if (balance?.value && price) {
      const valueDecimals = balance?.decimals + Constants.ORACLE_PRICE_DECIMALS
      return formatValue(balance?.value * price, valueDecimals)
    }
  }, [balance, price])

  const onChangeCollateral = useCallback(
    (e) => {
      switch (collateralTab) {
        case "add":
          if (Number(e.target.value) <= Number(balance?.formatted)) {
            const collateral = parseUnits(e.target.value, balance?.decimals) * price
            setCollateralAmount(collateral)
          } else {
            const maxCollateral = calculateCollateral(100, balance?.value) * price
            setCollateralAmount(maxCollateral)
          }
          break
        case "remove":
          const collateral = parseUnits(e.target.value, balance?.decimals) * price
          setCollateralAmount(collateral)
          break
        default:
          setCollateralAmount(0n)
          break
      }
    },
    [collateralTab, balance, price, calculateCollateral]
  )

  const headerCollateralModal = useMemo(() => {
    return (
      modalCollateralConfirm?.enabled && (
        <div className="flex flex-col gap-3 text-sm">
          <p className="font-medium text-left">
            Change Collateral -{" "}
            <span
              className={cx({
                "text-error": modalCollateralConfirm?.data?.type === "short",
                "text-success": modalCollateralConfirm?.data?.type === "long"
              })}
            >
              <span className="capitalize">{modalCollateralConfirm?.data?.type}</span>{" "}
              {modalCollateralConfirm?.data?.token}
            </span>
          </p>
          <div className="flex items-center rounded font-medium bg-slate-900 overflow-hidden w-full">
            <div
              className={cx({ "bg-default ": collateralTab === "add" }, "w-1/2 h-full py-2 cursor-pointer")}
              onClick={() => {
                setCollateralTab("add")
                setCollateralAmount(0n)
              }}
            >
              Add
            </div>
            <div
              className={cx({ "bg-red ": collateralTab === "remove" }, "w-1/2 h-full py-2 cursor-pointer")}
              onClick={() => {
                setCollateralTab("remove")
                setCollateralAmount(0n)
              }}
            >
              Remove
            </div>
          </div>
        </div>
      )
    )
  }, [modalCollateralConfirm, collateralTab])

  const bodyCollateralModal = useMemo(() => {
    return (
      modalCollateralConfirm.enabled && (
        <div className="text-sm mt-3">
          {/* input */}
          <div className="rounded p-3 flex flex-col gap-y-3 border">
            <div className="top flex justify-between">
              <p className="text-slate-500">Add</p>
              <div className="text-slate-500 dotted-underline cursor-pointer">
                {collateralTab === "add" && (
                  <div
                    onClick={() => {
                      const collateral = calculateCollateral(100, balance?.value)
                      setCollateralAmount(collateral * price)
                    }}
                  >
                    Max: {formatDecimals(balance?.formatted, 4)} ~ {tokenMaxValueInUSD}
                  </div>
                )}
                {collateralTab === "remove" && (
                  <div
                    onClick={() => {
                      const collateral = calculateCollateral(100, modalCollateralConfirm?.data?.raw?.collateralValue)
                      setCollateralAmount(collateral)
                    }}
                  >
                    Max: {modalCollateralConfirm?.data?.collateralValue}
                  </div>
                )}
              </div>
            </div>
            <div className="flex justify-between">
              <div>
                <input
                  type="number"
                  placeholder="0.0"
                  onChange={onChangeCollateral}
                  disabled={collateralTab === "remove"}
                  className="p-0"
                />
              </div>
              <div>{modalCollateralConfirm?.data?.token}</div>
            </div>
            <div className="bottom flex justify-between gap-3">
              {[25, 50, 75, 100].map((c, idx) => (
                <button
                  className={cx({
                    "bg-slate-800 w-1/4 py-1 rounded": true,
                    "bg-red":
                      collateralAmount === calculateCollateral(c, modalCollateralConfirm?.data?.raw?.collateralValue) &&
                      collateralTab === "remove",
                    "bg-default":
                      collateralAmount === calculateCollateral(c, balance?.value) * price && collateralTab === "add"
                  })}
                  key={idx}
                  onClick={() => {
                    if (collateralTab === "remove") {
                      const collateral = calculateCollateral(c, modalCollateralConfirm?.data?.raw?.collateralValue)
                      setCollateralAmount(collateral)
                    }
                    if (collateralTab === "add") {
                      const collateral = calculateCollateral(c, balance?.value)
                      setCollateralAmount(collateral * price)
                    }
                  }}
                >
                  {c} %
                </button>
              ))}
            </div>
          </div>
          {/* infor */}
          <div className="collateral-infor mt-3 flex flex-col gap-y-2">
            <div className="flex justify-between">
              <p className="text-slate-500">Position Size</p>
              <span className="">{modalCollateralConfirm?.data?.size}</span>
            </div>
            <div className="flex justify-between items-center">
              <p className="text-slate-500">Collateral Value ({modalCollateralConfirm?.data?.token})</p>
              <div className="flex items-center gap-1">
                <span className="text-slate-500">{modalCollateralConfirm?.data?.collateralValue}</span>
                <img src={ArrowLongRightIcon} alt="arrow right" className="w-5 h-5" />
                <span>{collateralValueToShow}</span>
              </div>
            </div>
            <div className="flex justify-between">
              <p className="text-slate-500">Leverage</p>
              <span className="">{modalCollateralConfirm?.data?.leverage}</span>
            </div>
            <div className="flex justify-between">
              <p className="text-slate-500">Index Price</p>
              <p>{modalCollateralConfirm?.data?.indexPrice}</p>
            </div>
            <div className="flex justify-between">
              <p className="text-slate-500">Liq.Price</p>
              <span className="">{modalCollateralConfirm?.data?.liquidationPrice}</span>
            </div>
          </div>
        </div>
      )
    )
  }, [
    modalCollateralConfirm,
    collateralTab,
    balance?.formatted,
    balance?.value,
    tokenMaxValueInUSD,
    onChangeCollateral,
    collateralValueToShow,
    calculateCollateral,
    price,
    collateralAmount
  ])

  const footerCollateralModal = useMemo(() => {
    return (
      <div className="collateral-footer mt-3">
        {collateralTab === "add" ? (
          <Button text="Add Collateral" isDefault={false} className="bg-default cursor-pointer py-2" />
        ) : (
          <Button text="Remove Collateral" isDefault={false} className="bg-red cursor-pointer py-2" />
        )}
      </div>
    )
  }, [collateralTab])

  return (
    <>
      <Modal
        open={open}
        setOpen={setOpen}
        header={headerCollateralModal}
        body={bodyCollateralModal}
        footer={footerCollateralModal}
      />
    </>
  )
}

export default CollateralPopup
