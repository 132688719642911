import { Constants } from "@void-0x/void-sdk"
import { useEffect, useMemo, useState } from "react"

import useWebSocket, { ReadyState } from "react-use-websocket"
import { useExchangeContext } from "src/contexts/ExchangeContext"
import { PriceDisplayDecimals } from "src/lib/decimal"
import { formatDollarDecimals } from "src/lib/formatter"
import { getSymbolFromPairName } from "src/lib/tokens"
import { formatUnits } from "viem"

const useMarketPrice = () => {
  const [currentPrice, setCurrentPrice] = useState(0)
  const [, setPreviousPrice] = useState(0)
  const [priceChange, setPriceChange] = useState(0)

  const { pair } = useExchangeContext()
  const { sendMessage, lastMessage, readyState, getWebSocket } = useWebSocket("wss://api.void.exchange")

  const pairName = getSymbolFromPairName(pair)
  useEffect(() => {
    const option = pair === "" ? "BTC" : pairName

    if (readyState === ReadyState.OPEN) {
      sendMessage(JSON.stringify({ type: "subscribe", channels: [option] }))
    }

    return () => {
      sendMessage(JSON.stringify({ type: "unsubscribe", channels: [option] }))
    }
  }, [getWebSocket, pair, pairName, readyState, sendMessage])

  useEffect(() => {
    if (lastMessage) {
      try {
        const data = JSON.parse(lastMessage?.data)
        if (data && data.p && Number(formatUnits(data.p, Constants.USD_DECIMALS)) !== Number(currentPrice)) {
          setPriceChange(Number(formatUnits(data.p, Constants.USD_DECIMALS)) - Number(currentPrice))
          setPreviousPrice(Number(currentPrice))
          setCurrentPrice(formatUnits(data.p, Constants.USD_DECIMALS))
        }
      } catch (err) {
        console.error(err)
      }

      return undefined
    }
  }, [currentPrice, lastMessage, priceChange])

  const status = useMemo(() => {
    return priceChange < 0 ? -1 : 1
  }, [priceChange])

  return { price: formatDollarDecimals(currentPrice, PriceDisplayDecimals[pairName]), status }
}

export default useMarketPrice
