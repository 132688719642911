import { useSubgraphQuery } from "./useSubgraphQuery"

const composeOrderQuery = ({ account }) => {
  return `
   {
     orders(where:{account: "${account}", executed: false}, orderBy: createdAt, orderDirection: desc) {
      account
      blockNumber
      cancelled
      collateralAmount
      collateralToken
      createdAt
      executed
      isLong
      id
      open
      sizeDelta
      triggerPrice
      slPrice
      tpPrice
      orderType
      slPrice
      tpPrice
      createdAt
      executed
      txHash
      market {
        name
        category
        id
        indexToken
      }
     }
   }
  `
}

/**
 * useOrders.
 *
 * @param {Number} chainId
 * @param {Object} object
 * @param {Object.string} account
 */

const useOrders = (chainId, { account }) => {
  const query = composeOrderQuery({ account })
  const data = useSubgraphQuery({ chainId, query, autoPolling: true, pollingInterval: 5000 })
  return data ? data.orders : []
}

export default useOrders
