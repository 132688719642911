import { CoinbaseIcon } from "@icons/index"
import { Arbitrum, Base, EthereumChain, Metamask } from "@img/logo"
import { Constants } from "@void-0x/void-sdk"

const { ChainId } = Constants

export const chainIcon = {
  1: EthereumChain,
  84531: Base,
  11155111: EthereumChain,
  5: Base,
  421613: Arbitrum
}

export const connectorIcon = {
  metaMask: Metamask,
  coinbaseWallet: CoinbaseIcon
}

export const isChainSupported = (chain) => {
  if (!chain) {
    return false
  }

  const chainIds = [ChainId.ArbitrumGoerli, ChainId.BaseGoerli]
  return chainIds.includes(chain.id)
}
