import { useEffect, useState } from "react"
import { SubgraphEndpoints } from "src/constant/subgraph"

export const useSubgraphQuery = ({ chainId, query, autoPolling = false, pollingInterval = 3000 }) => {
  const [data, setData] = useState({})

  const fetchData = async () => {
    try {
      const endpoint = SubgraphEndpoints[chainId]
      if (!endpoint || !query) return

      const resp = await fetch(endpoint, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ query, variables: null })
      })

      const data = await resp.json()
      setData(data.data)
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    fetchData()

    if (autoPolling) {
      // Polling logic
      const interval = setInterval(fetchData, pollingInterval)

      // Clean up the interval when the component unmounts
      return () => clearInterval(interval)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chainId, pollingInterval, autoPolling, query])

  return data
}
