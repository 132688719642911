import { Constants } from "@void-0x/void-sdk"
import { USDC, ETH, BTC, SOLANA, MATIC, CAKE, DOGE, PEPE, WETH, UKNOWN, APTOS, SUI, CYBER, SEI } from "@img/token"
import { getChecksumAddress } from "./address"

const { ChainId } = Constants

const AddressToSymbolMap = {
  [Constants.ChainId.BaseGoerli]: {
    [getChecksumAddress(Constants.Addresses[ChainId.BaseGoerli].IndexTokens.WBTC)]: "WBTC",
    [getChecksumAddress(Constants.Addresses[ChainId.BaseGoerli].IndexTokens.WETH)]: "WETH",
    [getChecksumAddress(Constants.Addresses[ChainId.BaseGoerli].StableCoins.USDC)]: "USDC"
  },

  [Constants.ChainId.ArbitrumGoerli]: {
    [getChecksumAddress(Constants.Addresses[ChainId.ArbitrumGoerli].IndexTokens.WBTC)]: "WBTC",
    [getChecksumAddress(Constants.Addresses[ChainId.ArbitrumGoerli].IndexTokens.WETH)]: "WETH",
    [getChecksumAddress(Constants.Addresses[ChainId.ArbitrumGoerli].IndexTokens.USDC)]: "USDC"
  },

  [Constants.ChainId.Sepolia]: {
    [getChecksumAddress(Constants.Addresses[ChainId.Sepolia].IndexTokens.WBTC)]: "WBTC",
    [getChecksumAddress(Constants.Addresses[ChainId.Sepolia].IndexTokens.WETH)]: "WETH"
  }
}

const MapSymbolToIcon = {
  USDC: USDC,
  ETH: ETH,
  BTC: BTC,
  SOL: SOLANA,
  MATIC: MATIC,
  CAKE: CAKE,
  DOGE: DOGE,
  PEPE: PEPE,
  WETH: WETH,
  WBTC: BTC,
  SUI: SUI,
  APT: APTOS,
  CYBER: CYBER,
  SEI: SEI,
  RISKY: UKNOWN
}

const TokenName = {
  BTC: "Bitcoin",
  ETH: "Ethereum",
  RISKY: "Risky",
  APT: "Aptos",
  SUI: "Sui",
  DOGE: "Doge",
  PEPE: "Pepe",
  SEI: "SEI",
  CYBER: "CYBER",
  USDC: 'USDC'
}

const Tokens = {
  [Constants.ChainId.BaseGoerli]: {
    WBTC: {
      address: Constants.Addresses[ChainId.BaseGoerli].IndexTokens.WBTC,
      decimals: 8,
      icon: BTC,
      symbol: "WBTC",
      name: "BTC"
    },
    WETH: {
      address: Constants.Addresses[ChainId.BaseGoerli].IndexTokens.WETH,
      decimals: 18,
      icon: ETH,
      symbol: "WETH",
      name: "ETH"
    },
    USDC: {
      address: Constants.Addresses[ChainId.BaseGoerli].StableCoins.USDC,
      decimals: 18,
      icon: USDC,
      symbol: "USDC",
      name: "USDC"
    }
  },

  [Constants.ChainId.Sepolia]: {
    WBTC: {
      address: Constants.Addresses[ChainId.Sepolia].IndexTokens.WBTC,
      decimals: 8,
      icon: BTC,
      symbol: "WBTC",
      name: "BTC"
    },
    WETH: {
      address: Constants.Addresses[ChainId.Sepolia].IndexTokens.WETH,
      decimals: 18,
      icon: ETH,
      symbol: "WETH",
      name: "ETH"
    }
  },
  [Constants.ChainId.ArbitrumGoerli]: {
    WBTC: {
      address: Constants.Addresses[ChainId.ArbitrumGoerli].IndexTokens.WBTC,
      decimals: 8,
      icon: BTC,
      symbol: "WBTC",
      name: "BTC"
    },
    WETH: {
      address: Constants.Addresses[ChainId.ArbitrumGoerli].IndexTokens.WETH,
      decimals: 18,
      icon: ETH,
      symbol: "WETH",
      name: "ETH"
    },
    USDC: {
      address: Constants.Addresses[ChainId.ArbitrumGoerli].IndexTokens.USDC,
      decimals: 18,
      icon: USDC,
      symbol: "USDC",
      name: "USDC"
    }
  }
}

/**
 * getSymbolFromPairName: Given a pair name. Returns the symbol of the token
 * E.g: BTC/USD => BTC
 * @param {String} pairName
 * @return {String} symbol
 */
const getSymbolFromPairName = (pairName) => {
  if (!pairName) return ""
  const splits = pairName.split("/")

  // Warning: temporarily harcoding
  if (splits[0] === "WETH") {
    return "ETH"
  }

  return splits[0]
}

export { AddressToSymbolMap, Tokens, getSymbolFromPairName, MapSymbolToIcon, TokenName }
