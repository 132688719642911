import { useMemo, useState } from "react"
import { usePublicClient, useWalletClient, useNetwork } from "wagmi"
import { TradingEngine, Constants } from "@void-0x/void-sdk"
import { isChainSupported } from "src/lib/chains"

const useTradingEngine = () => {
  const [isLoading, setIsLoading] = useState(false)

  const publicClient = usePublicClient()
  const { data: walletClient, isLoading: isWalletLoading } = useWalletClient()
  const { chain } = useNetwork()

  const tradingEngine = useMemo(() => {
    if (chain && !isWalletLoading && isChainSupported(chain)) {
      return new TradingEngine(publicClient, walletClient, Constants.Addresses[chain?.id].TradingEngine)
    }
  }, [publicClient, walletClient, isWalletLoading, chain])

  const claimFundingPayout = async ({ marketId, collateralToken, isLong }) => {
    if (!tradingEngine) {
      return
    }

    try {
      setIsLoading(true)

      const hash = await tradingEngine.claimFundingPayout(
        {
          marketId,
          collateralToken,
          isLong
        },
        {
          simulate: true // static call before making real transaction
        }
      )
      await publicClient.waitForTransactionReceipt({ hash })
      setIsLoading(false)
    } catch (error) {
      // TODO: handle error
      console.info("Error", error)
    }
  }

  return { isLoading, claimFundingPayout }
}

export default useTradingEngine
