import Footer from "@components/Footer/Footer"
import Header from "@components/Header/Header"
import React from "react"

const PageWithHeadAndFoot = ({ children, isShowHeader = true, isShowFooter = true, className }) => {
  return (
    <div className={className}>
      {isShowHeader && <Header />}
      {children}
      {isShowFooter && <Footer />}
    </div>
  )
}

export default PageWithHeadAndFoot
