import React from "react"
import cx from "classnames"
import { isChainSupported } from "src/lib/chains"
import { useNetwork } from "wagmi"
import NoDataIcon from "@img/icons/noData.svg"

// we must define header match with data
const dataTest = [
  {
    name: "test",
    age: "test",
    status: "test",
    date: "hi",
    gen: "N"
  }
]

const columnDefEx = [
  {
    field: "name",
    headerName: "Name",
    cellRenderer: (val) => {
      return val
    },
    formatter: (val) => {
      return val
    },
    className: "",
    headerClassName: ""
  },
  {
    field: "age",
    headerName: "Age"
  },
  {
    field: "status",
    headerName: "Status"
  },
  {
    field: "date",
    headerName: "Date"
  },
  {
    field: "gen",
    headerName: "Gen",
    sortable: true,
    sortbyOrder: "asc"
  }
]

const TableCustom = ({
  columnDef = columnDefEx,
  data,
  isShadow = false,
  cellStyle = "",
  isBorderHeader,
  headerClassName,
  rowStyle = "",
  requireConnect = true
}) => {
  const { chain } = useNetwork()

  const HeaderRow = ({ item }) => {
    return (
      <th className={cx("py-1 whitespace-nowrap", item.headerClassName, headerClassName)}>
        <p className={cx("font-normal cursor-pointer hover:text-white", { "border-b": isBorderHeader })}>
          {item?.headerName}
        </p>
      </th>
    )
  }

  const BodyRow = ({ item }) => {
    return (
      <tr className={cx("text-center", rowStyle)}>
        {columnDef.map((h) => {
          if (h.cellRenderer) {
            return (
              <td className={cx(cellStyle, h.className)} key={h.headerName}>
                {h.cellRenderer(item)}
              </td>
            )
          }
          if (h.formatter) {
            return (
              <td className={cx(cellStyle, h.className)} key={h.headerName}>
                {h.formatter(item)}
              </td>
            )
          }
          return (
            <td className={cx(cellStyle, h.className)} key={h.headerName}>
              {item?.[h?.field]}
            </td>
          )
        })}
      </tr>
    )
  }
  const NoData = () => {
    return (
      <tr>
        <td className=" text-slate-400" colSpan={columnDef.length}>
          <div className="flex flex-col items-center pt-10 gap-1">
            <img src={NoDataIcon} alt="search" className="w-12 h-12" />
            <p className="capitalize text-xs">no data to show</p>
          </div>
        </td>
      </tr>
    )
  }

  const WrongNetwork = () => {
    return (
      <tr>
        <td className="text-center text-sm text-slate-200 p-14" colSpan={columnDef.length}>
          <div className="border inline-block p-3 rounded cursor-pointer text-slate-400 capitalize">Wrong Network</div>
        </td>
      </tr>
    )
  }

  const renderTable = () => {
    if (requireConnect && !isChainSupported(chain)) {
      return <WrongNetwork />
    }
    if (data?.length === 0) {
      return <NoData />
    }

    return data.map((item) => <BodyRow item={item} key={item.id} />)
  }

  return (
    <div
      className={cx({
        "w-full rounded": true,
        shadow: isShadow
      })}
    >
      <div className="w-full overflow-x-auto no-scrollbar">
        <table className="w-full">
          <thead>
            <tr className={cx("tracking-wide border-b text-slate-500")} key={0}>
              {columnDef.map((item) => (
                <HeaderRow key={item.headerName} item={item} />
              ))}
            </tr>
          </thead>

          <tbody>{renderTable()}</tbody>
        </table>
      </div>
    </div>
  )
}

export default TableCustom
