import { useSubgraphQuery } from "./useSubgraphQuery"

const composePositionQuery = ({ account }) => {
  return `
  {
    positions(where: { account: "${account}", closed: false }, orderBy: updatedAt, orderDirection: desc) {
      account
      collateralToken
      collateralValue
      createdAt
      updatedAt
      entryFundingIndex
      entryPayoutIndex
      entryPrice
      id
      key
      size
      isLong
      market {
        id
        indexToken
        marketId
        category
        maxLeverage
        name
        longFundingIndex
        shortFundingIndex
        longPayoutIndex
        shortPayoutIndex
        closeFeeRate
        liquidationFeeRate
        fundingRatePrecision
        maintenanceMarginBps
      }
    }
  }
 `
}
/**
 * usePositions.
 *
 * @param {Number} chainId
 * @param {String} account
 */

const usePositions = (chainId, account) => {
  const query = composePositionQuery({ account })
  const data = useSubgraphQuery({ chainId, query, autoPolling: true, pollingInterval: 5000 })

  return data ? data.positions : []
}

export default usePositions
