/* global BigInt */
import React, { useMemo, useState } from "react"
import TableCustom from "@components/Table/TableCustom"
import cx from "classnames"
import Button from "@components/Button/Button"
import { useAccount, useNetwork } from "wagmi"
import useOrders from "src/hooks/subgraph/useOrders"
import { timeAgo } from "src/lib/dates"
import { Constants } from "@void-0x/void-sdk"
import { formatPrice, descaleValue, formatValue } from "src/lib/formatter"
import { getChecksumAddress } from "src/lib/address"
import { AddressToSymbolMap, MapSymbolToIcon, getSymbolFromPairName } from "src/lib/tokens"
import { useExchangeContext } from "src/contexts/ExchangeContext"
import { isChainSupported } from "src/lib/chains"
import { ExpandIcon } from "@icons/index"
import { Link } from "react-router-dom"
import { etherScan } from "src/lib/etherscan"
import { OrderTypes } from "src/lib/market"
import useDecimals from "src/hooks/useDecimals"

const OrderLists = () => {
  const { cancelOrder, isCancelOrder } = useExchangeContext()
  const [cancelId, setCancelId] = useState()

  const columnDef = [
    {
      field: "createdAt",
      headerName: "Time",
      headerClassName: "text-left text-xs pl-3 w-48",
      cellRenderer: (cell) => {
        return timeAgo(cell.createdAt)
      },
      className: "text-left pl-3"
    },
    {
      field: "custom",
      headerName: "Market & Side",
      headerClassName: "text-left text-xs pl-3 w-48",
      cellRenderer: (cell) => {
        const pairName = getSymbolFromPairName(cell?.market?.name)
        const token = MapSymbolToIcon[pairName]

        return (
          <div className="text-left flex items-center gap-2">
            <img src={token} alt="token market" className="w-6 h-6 inline-block" />
            <div>
              <h3>{cell?.market?.name}</h3>
              <span className={cx({ "text-success": cell.isLong, "text-error": !cell.isLong })}>
                {cell?.isLong ? "Long" : "Short"}
              </span>
            </div>
          </div>
        )
      }
    },
    {
      field: "open",
      headerName: "Operation",
      headerClassName: "text-xs pl-3",
      cellRenderer: (cell) => {
        return (
          <div className="capitalize">
            <h3>{cell?.open === true ? "Open Position" : "Close Position"}</h3>
          </div>
        )
      }
    },
    {
      field: "orderType",
      headerName: "Order Type",
      headerClassName: "text-xs pl-3 w-48",
      cellRenderer: (cell) => {
        return <div className="text-slate-500">{OrderTypes[cell?.orderType]}</div>
      }
    },
    {
      field: "price",
      headerName: "Trigger Price",
      headerClassName: "text-xs pl-3 w-48",
      cellRenderer: (cell) => formatPrice(cell.triggerPrice, Constants.USD_DECIMALS)
    },
    {
      field: "collateralAmount",
      headerName: "Collateral Amount",
      headerClassName: "text-xs pl-3 w-48",
      cellRenderer: (cell) => {
        const chainId = cell?.chainId
        const collateralToken = getChecksumAddress(cell?.collateralToken)
        const symbol = AddressToSymbolMap[chainId][collateralToken]
        const token = MapSymbolToIcon[symbol]

        const decimal = decimals[cell.collateralToken]
        if (!decimal) {
          return "--"
        }

        return (
          <div className="flex justify-center items-center gap-1 w-1/3 m-auto">
            {cell?.collateralAmount > BigInt(0) ? (
              <>
                <img src={token} alt="collateral token" className="w-6 h-6" />
                <h3>{descaleValue(cell?.collateralAmount, decimal)}</h3>
              </>
            ) : (
              "--"
            )}
          </div>
        )
      }
    },
    {
      field: "positionSize",
      headerName: "Position Size",
      headerClassName: "text-xs pl-3 w-48",
      cellRenderer: (cell) => formatValue(cell.sizeDelta, Constants.USD_DECIMALS)
    },
    {
      field: "takeProfitAndStopLoss",
      headerName: "TP/SL",
      headerClassName: "text-xs pl-3 w-48",
      cellRenderer: (cell) => {
        return (
          <div className="flex items-center gap-1 justify-center">
            <span>{Number(cell?.slPrice) === 0 ? "--" : formatValue(cell.slPrice, Constants.USD_DECIMALS, false)}</span>
            <span>/</span>
            <span>{Number(cell?.slPrice) === 0 ? "--" : formatValue(cell.tpPrice, Constants.USD_DECIMALS, false)}</span>
          </div>
        )
      }
    },
    {
      field: "id",
      headerName: "Actions",
      headerClassName: "text-xs pl-3 w-48",
      cellRenderer: (cell) => {
        return (
          <Button
            isDefault={false}
            text="Cancel"
            onClick={() => {
              setCancelId(cell.id)
              cancelOrder(BigInt(cell.id))
            }}
            className="bg-slate-800 rounded inline-block w-1/2 m-auto py-2 cursor-pointer text-xs"
            isLoading={isCancelOrder && cell.id === cancelId}
          />
        )
      }
    },
    {
      field: "expand",
      headerName: "Expand",
      headerClassName: "text-xs pr-3",
      cellRenderer: (cell) => {
        const etherLink = etherScan[cell?.chainId]
        const txHash = cell?.txHash
        const destination = etherLink + txHash

        return (
          <Link className="flex items-center justify-center" to={destination} target="_blank">
            <img className="w-4 h-4" alt="expand" src={ExpandIcon} />
          </Link>
        )
      }
    }
  ]

  const { chain } = useNetwork()
  const { address } = useAccount()
  const orders = useOrders(chain?.id, { account: address })

  const allCollateralTokens = useMemo(() => {
    if (!orders || !orders.length) {
      return []
    }

    // return uniques collateral tokens
    return [...new Set(orders.map((order) => order.collateralToken))]
  }, [orders])

  const { decimals } = useDecimals(allCollateralTokens)

  const formattedData = useMemo(() => {
    if (isChainSupported(chain.id)) return []

    return orders && orders.length > 0
      ? orders.map((order) => {
          return {
            ...order,
            chainId: chain.id
          }
        })
      : []
  }, [chain, orders])

  return (
    <div className="vh-20 w-full overflow-y-auto no-scrollbar">
      <TableCustom columnDef={columnDef} data={formattedData} cellStyle="py-3 text-xs" isBorderHeader={false} />
    </div>
  )
}

export default OrderLists
