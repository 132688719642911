import React, { useCallback, useEffect } from "react"
import cx from "classnames"
import LoadingLine from "../LoadingLine/LoadingLine"

const Toast = ({ toastList = [], position = "center", setToastList, className, duration = 5000 }) => {
  const deleteToast = useCallback(
    (id) => {
      const toastListItem = toastList.filter((e) => e.id !== id)
      setToastList(toastListItem)
    },
    [toastList, setToastList]
  )

  useEffect(() => {
    const interval = setInterval(() => {
      if (toastList.length) {
        deleteToast(toastList[0].id)
      }
    }, duration)

    return () => {
      clearInterval(interval)
    }
  }, [toastList, deleteToast, duration])

  return (
    <div
      className={cx("fixed z-50", {
        "bottom-3 right-3": position === "bottom-right",
        "top-20 left-1/2 -translate-x-1/2": position === "center"
      })}
    >
      {toastList.map((toast, idx) => (
        <div
          className={cx(
            "card border shadow w-96 rounded p-3 text-sm mt-1 flex flex-col gap-2",
            {
              "border-green": toast.type === "success",
              "border-red": toast.type === "error",
              "border-yellow": toast.type === "pending",
              "border-default": toast.type === "default",
              "toast-in-right": position === "bottom-right"
            },
            className
          )}
          key={idx}
        >
          {toast.body}

          {toast.showLoadingLine && <LoadingLine type={toast.type} />}
        </div>
      ))}
    </div>
  )
}

export default Toast
