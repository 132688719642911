import TableCustom from "@components/Table/TableCustom"
import { CancelDefaultCircle } from "@icons/index"
import { Constants } from "@void-0x/void-sdk"
import React from "react"
import { useExchangeContext } from "src/contexts/ExchangeContext"
import { useExchangeUIContext } from "src/contexts/ExchangeUIContext"
import useLatestTrades from "src/hooks/subgraph/useLatestTrades"
import { getTime } from "src/lib/dates"
import { formatPrice, formatValue } from "src/lib/formatter"
import { useNetwork } from "wagmi"
import cx from "classnames"

const LatestTrade = () => {
  const { onChangeCloseFundingAndHistory } = useExchangeUIContext()
  const { market } = useExchangeContext()
  const { chain } = useNetwork()

  const data = useLatestTrades({ chainId: chain?.id, marketId: market?.id })

  const columnDef = [
    {
      field: "createdAt",
      headerName: "Time",
      headerClassName: "text-sm text-left",
      cellRenderer: (cell) => {
        if (cell) {
          const formattedTime = getTime(cell?.createdAt)
          return <div className="text-left">{formattedTime}</div>
        }
        return <div>--</div>
      }
    },
    {
      field: "collateralValue",
      headerName: `Value`,
      headerClassName: "text-sm",
      cellRenderer: (cell) => {
        if (cell) {
          const collateralValue = cell?.collateralValue
          return <div>{formatValue(collateralValue, Constants.USD_DECIMALS)}</div>
        }
        return <div>--</div>
      }
    },
    {
      field: "collateralValue",
      headerName: "Price",
      headerClassName: "text-sm text-right",
      cellRenderer: (cell) => {
        if (cell && cell.executedPrice) {
          const formattedPrice = formatPrice(cell.executedPrice, Constants.USD_DECIMALS)
          const isLong = cell?.isLong
          return (
            <div className={cx({ "text-success": isLong, "text-error": !isLong }, "text-right")}>{formattedPrice}</div>
          )
        }
        return <div>--</div>
      }
    }
  ]

  return (
    <div className=" p-3">
      <div className="flex items-center justify-between">
        <h3 className="text-sm">Latest Trade</h3>
        <img
          src={CancelDefaultCircle}
          alt="right-left"
          className="w-6 h-6 cursor-pointer"
          onClick={onChangeCloseFundingAndHistory}
        />
      </div>
      <div className="vh-60 overflow-y-auto no-scrollbar">
        <TableCustom columnDef={columnDef} data={data || []} cellStyle="py-3 text-xs" />
      </div>
    </div>
  )
}

export default LatestTrade
