import { createContext, useCallback, useContext, useState } from "react"

const ExchangeUIContext = createContext(null)

/**
 * A hook to return the Exchange UI context object.
 * @function useExchangeUIContext
 * @returns {ExchangeContextType} - The Exchange context object.
 * @throws {Error} - Throws if context is undefined.
 */

export const useExchangeUIContext = () => {
  const context = useContext(ExchangeUIContext)
  if (context === undefined) {
    throw new Error("Context must be used within a Provider")
  }
  return context
}

export const ExchangeUIContextProvider = ({ children }) => {
  const [modalOrderComfirm, setModalPlaceOrderConfirm] = useState({
    enabled: false,
    data: null,
    type: null
  })
  const [modalCloseOrderConfirm, setModalCloseOrderConfirm] = useState({
    enabled: false,
    data: null,
    type: null
  })
  const [modalCollateralConfirm, setModalCollateralConfirm] = useState({
    enabled: false,
    data: null,
    type: null
  })
  const [modalStopLoss, setModalStopLoss] = useState({
    enabled: false,
    data: null,
    side: null
  })

  const [isShowHistoyTrade, setIsShowHistoryTrade] = useState(false)
  const [isShowFundingHistory, setIsShowFundingHistory] = useState(false)
  const [isStopLossModal, setIsStopLossModal] = useState(false)
  const [collateralModal, setCollateralModal] = useState(false)
  const [listOrders, setListOrders] = useState([])

  const onChangeCloseFundingAndHistory = () => {
    setIsShowFundingHistory(false)
    setIsShowHistoryTrade(false)
  }

  const handleModalStoploss = useCallback((side) => {
    setIsStopLossModal(true)
    setModalStopLoss({
      enabled: true,
      data: {
        side: side
      }
    })
  }, [])

  return (
    <ExchangeUIContext.Provider
      value={{
        modalOrderComfirm,
        setModalPlaceOrderConfirm,
        modalCloseOrderConfirm,
        setModalCloseOrderConfirm,
        modalCollateralConfirm,
        setModalCollateralConfirm,
        modalStopLoss,
        setModalStopLoss,
        isShowHistoyTrade,
        setIsShowHistoryTrade,
        isShowFundingHistory,
        setIsShowFundingHistory,
        onChangeCloseFundingAndHistory,
        listOrders,
        setListOrders,
        handleModalStoploss,
        isStopLossModal,
        setIsStopLossModal,
        collateralModal,
        setCollateralModal
      }}
    >
      {children}
    </ExchangeUIContext.Provider>
  )
}

/**
 * The Exchange context object.
 * @typedef {Object} ExchangeContextType
 * @property {Object} modalOrderComfirm - The object state for modal confirm.
 * @property {Boolean} isShowHistoyTrade - The boolean state for history trade.
 * @property {Boolean} isShowFundingHistory - The boolean state for history funding state.
 * @property {Function} setIsShowHistoryTrade - The function set state for history trade.
 * @property {Function} setIsShowFundingHistory - The function set state for history funding state.
 * @property {Object} modalCloseOrderConfirm - The object state for modal confirm.
 * @property {Object} modalCollateralConfirm - The object state for modal confirm.
 * @property {Object} modalStopLoss - The object state for modal confirm.
 * @property {Array} listOrders - The array state.
 * @property {Function} setListOrders - The function set state.
 * @property {Function} setModalPlaceOrderConfirm - The Function set state for modal confirm.
 * @property {Function} setModalCloseOrderConfirm - The Function set state for modal confirm.
 * @property {Function} setModalCollateralConfirm - The Function set state for modal confirm.
 * @property {Function} setModalStopLoss - The Function set state for modal confirm.
 * @property {Function} onChangeCloseFundingAndHistory - The Function set state for close funding and trade history.
 * @property {Function} handleModalStoploss - The Function set state for modal stop loss.
 * @property {Boolean} isStopLossModal - The Function set state for modal stop loss.
 * @property {Function} setIsStopLossModal - The Function set state for modal stop loss.
 * @property {Boolean} collateralModal - The state for modal collateral.
 * @property {Function} setCollateralModal - The function set state for modal collateral.
 */
