const { useSubgraphQuery } = require("./useSubgraphQuery")

const composeTradeHistoryQuery = ({ account }) => {
  return `
    {
        histories(where: {account: "${account}"}, orderBy: createdAt, orderDirection: desc) {
          collateralAmount
          collateralToken
          collateralValue
          createdAt
          feeUsd
          isLong
          txHash
          id
          market {
            name
            indexToken
          }
          pnl
          sizeDelta
          status
          executedPrice
        }
      }
   `
}

/**
 * useTradeHistory.
 *
 * @param {Number} chainId
 * @param {String} account
 */

const useTradeHistory = (chainId, account) => {
  const query = composeTradeHistoryQuery({ account })
  const data = useSubgraphQuery({ chainId, query, autoPolling: true, pollingInterval: 3000 })

  return data ? data.histories : []
}

export default useTradeHistory
