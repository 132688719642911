import React from "react"
import { useAccount, useConnect, useNetwork, useSwitchNetwork } from "wagmi"
import cx from "classnames"
import { chainIcon, connectorIcon, isChainSupported } from "src/lib/chains"
import Button from "@components/Button/Button"

const sortIds = [84531, 421613]

const RequireConnection = ({ children }) => {
  const { isConnected } = useAccount()
  const { connect, connectors } = useConnect()
  const { chain: currentChain, chains } = useNetwork()
  const { switchNetwork, isLoading: isLoadingSwitchNetwork, pendingChainId } = useSwitchNetwork()

  const correctChain = isChainSupported(currentChain)

  const listChains = chains
    .map((chain) => ({
      ...chain,
      icon: chainIcon[chain.id]
    }))
    .sort((a, b) => sortIds.indexOf(a.id) - sortIds.indexOf(b.id))

  if (!isConnected) {
    return (
      <div>
        <div
          className={cx({
            "absolute top-0 left-0 blur-bg w-full h-full": true
          })}
        >
          <div className="rounded card w-1/4 p-3 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col gap-3">
            <div className="text-sm text-center">
              <h3>Connect Your Wallet</h3>
              <span className="text-sm text-slate-500">Select your wallet from these supported options.</span>
            </div>
            <div className="flex flex-wrap gap-3 p-3 bg-gray-800 rounded">
              {connectors.map((connector) => {
                const textBtn = !connector.ready ? " (unsupported)" : connector.name
                return (
                  <Button
                    text={textBtn}
                    onClick={() => {
                      connect({ connector })
                    }}
                    isDefault={false}
                    className="p-3 cursor-pointer card"
                    icon={<img src={connectorIcon[connector.id]} alt="icon" className="h-4 w-4" />}
                    key={connector.id}
                  />
                )
              })}
            </div>
          </div>
        </div>
      </div>
    )
  }

  if (!correctChain) {
    return (
      <div className="w-full h-full">
        <div className="fixed inset-0 bg-black bg-opacity-70 pointer-events-none z-40" />
        <div className="rounded card w-1/4 p-5 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col gap-3 z-50">
          <div className="text-sm text-center">
            <h3>Switch Chain</h3>
            <p className="text-slate-500">Select list chain support</p>
          </div>
          <div className="flex flex-wrap gap-3 p-3 bg-gray-800 rounded">
            {listChains.map((chain) => (
              <Button
                text={chain.name}
                isDefault={false}
                className="p-2 cursor-pointer card"
                icon={<img src={chain.icon} alt="icon" className="h-4 w-4" />}
                key={chain.id}
                onClick={() => switchNetwork(chain.id)}
                isLoading={isLoadingSwitchNetwork && pendingChainId === chain.id}
              />
            ))}
          </div>
        </div>
      </div>
    )
  }
  return <div className="w-full h-full">{children}</div>
}

export default RequireConnection
