import PageWithHeadAndFoot from "@components/common/PageWithHeadAndFoot/PageWithHeadAndFoot"
import SEO from "@components/common/SEO"
import React from "react"
import { Outlet } from "react-router-dom"
import { getPageTitle } from "src/lib/utils"

const VaultPage = () => {
  return (
    <SEO title={getPageTitle("Earn")}>
      <PageWithHeadAndFoot className="flex flex-col gap-20">
        <Outlet />
      </PageWithHeadAndFoot>
    </SEO>
  )
}

export default VaultPage
