import { OrderType } from "@void-0x/void-sdk"
import { Constants } from "@void-0x/void-sdk"
import { getChecksumAddress } from "./address"

export const OrderTypes = {
  [OrderType.MARKET]: "Market",
  [OrderType.LIMIT]: "Limit",
  [OrderType.STOP_MARKET]: "Stop Market",
  [OrderType.TP_SL]: "TP SL"
}

export const MarketTypes = {
  Standard: 0,
  Synthetic: 1,
  SyntheticNoIndex: 2 // synthetic market without index token. For example: commodities/ Forex
}

export const DefaultMarkets = {
  [Constants.ChainId.ArbitrumGoerli]: {
    id: "0x13033534e15d00b3220a15d3b70ef61a7827f940c549fcb1da8049cc5cc24eeb",
    owner: "0x42ac2e40f78f8b350cb89801ef730a6f6eed78ad",
    name: "BTC/USD",
    category: 0,
    marketType: 0,
    marketId: "0x13033534e15d00b3220a15d3b70ef61a7827f940c549fcb1da8049cc5cc24eeb",
    quoteToken: getChecksumAddress("0xd01992309a8325272c8a8c6d5a845dff16f1bcdf"),
    indexToken: getChecksumAddress("0x0f1292649e18c343e274d99b1d3a50bc07b4bb54")
  },
  [Constants.ChainId.BaseGoerli]: {
    id: "0xfe3d5569a1462cfad31c0f7121f8641a9f3be889fba5231e0a168df31d585d45",
    owner: "0x42ac2e40f78f8b350cb89801ef730a6f6eed78ad",
    name: "BTC/USD",
    category: 0,
    marketType: 0,
    marketId: "0xfe3d5569a1462cfad31c0f7121f8641a9f3be889fba5231e0a168df31d585d45",
    quoteToken: getChecksumAddress("0xbb07e3fd0b1c5bf4cd7de3a232e0bd64400ad900"),
    indexToken: getChecksumAddress("0x62024b73b4fb1a2b4a80476b3d57bee0b6448e56")
  }
}
