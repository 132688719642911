import { getAddress } from "viem"

/**
 * getChecksumAddress.
 *
 * @param {String} address
 */
export const getChecksumAddress = (address) => {
  return getAddress(address)
}
