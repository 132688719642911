import React, { useMemo } from "react"

import cx from "classnames"

import Button from "@components/Button/Button"
import Modal from "@components/Modal/Modal"
import { InputCustom } from "@components/common"

import { useExchangeUIContext } from "src/contexts/ExchangeUIContext"

const Label = {
  0: "Long",
  1: "Short"
}
const ProfitStopLossModal = ({ disabled }) => {
  const { modalStopLoss, setIsStopLossModal, isStopLossModal } = useExchangeUIContext()

  const header = useMemo(() => {
    return (
      modalStopLoss?.enabled && (
        <div>
          <h2 className="text-left">
            Take Profit / Stop Loss -{" "}
            <span
              className={cx({
                "text-success": modalStopLoss?.data?.side === 0,
                "text-error": modalStopLoss?.data?.side === 1
              })}
            >
              {Label[modalStopLoss?.data?.side]}
            </span>
          </h2>
        </div>
      )
    )
  }, [modalStopLoss])

  const body = (
    <div className="flex flex-col gap-3">
      <div className="flex items-center gap-2">
        <InputCustom
          label="Take Profit"
          classNameInput="py-2 px-1 text-sm"
          leftSide={
            <div className="flex items-center gap-1 text-sm">
              <span className="text-slate-500">TP:</span>
              <span className="text-slate-500">≥</span>
              <span>$</span>
            </div>
          }
        />
        <InputCustom classNameInput="py-2 px-1 text-sm" label="Gain" rightAction="%" />
      </div>
      <div className="text-sm text-left text-slate-400">
        When Index Price reaches <span className="text-white">$--</span>, it will trigger Market order, and the
        estimated profit of closing -- ETH from the position will be <span className="text-success">+ $--</span>.
      </div>
      <div className="flex items-center gap-2">
        <InputCustom
          label="Stop Loss"
          classNameInput="py-2 px-1 text-sm"
          leftSide={
            <div className="flex items-center gap-1">
              <span className="text-slate-500">SL:</span>
              <span className="text-slate-500">≤</span>
              <span>$</span>
            </div>
          }
        />
        <InputCustom classNameInput="py-2 px-1 text-sm" label="Gain" rightAction="%" />
      </div>
      <div className="text-sm text-left text-slate-400">
        When Index Price reaches <span className="text-white">$--</span>, it will trigger Market order, and the
        estimated profit of closing -- ETH from the position will be <span className="text-error">- $--</span>.
      </div>
    </div>
  )

  const footer = (
    <div>
      <Button text="Confirm" />
    </div>
  )
  return (
    <Modal
      open={isStopLossModal}
      setOpen={setIsStopLossModal}
      disabled={disabled}
      header={header}
      body={body}
      footer={footer}
    />
  )
}

export default ProfitStopLossModal
