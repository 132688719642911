import { ExchangeUIContextProvider } from "./contexts/ExchangeUIContext"

const { ExchangeContextProvider } = require("./contexts/ExchangeContext")
const { default: Dashboard } = require("./pages/Dashboard/Dashboard")
const { default: DocumentPage } = require("./pages/DocumentPage/DocumentPage")
const { default: Exchange } = require("./pages/Exchange/Exchange")
const { default: FaucetPage } = require("./pages/FaucetPage/FaucetPage")
const { default: MarketPage } = require("./pages/MarketPage/MarketPage")
const { default: ProfilePage } = require("./pages/ProfilePage/ProfilePage")
const { default: StakingPage } = require("./pages/StakingPage/StakingPage")
const { default: ListVault } = require("./pages/VaultPage/ListVault")
const { default: VaultPage } = require("./pages/VaultPage/VaultPage")
const { default: DetailVaultPage } = require("./pages/DetailVaultPage")

export const appRoutes = [
  {
    path: "/",
    element: <Dashboard />
  },
  {
    path: "/trade",
    element: (
      <ExchangeContextProvider>
        <ExchangeUIContextProvider>
          <Exchange />
        </ExchangeUIContextProvider>
      </ExchangeContextProvider>
    )
  },
  {
    path: "/document",
    element: <DocumentPage />
  },
  {
    path: "/profile",
    element: <ProfilePage />
  },
  {
    path: "/faucet",
    element: <FaucetPage />
  },
  {
    path: "/staking",
    element: <StakingPage />
  },
  {
    path: "/market",
    element: <MarketPage />
  },
  {
    path: "/vault",
    element: <VaultPage />,
    children: [
      {
        path: "list",
        element: <ListVault />
      },
      {
        path: ":id",
        element: <DetailVaultPage />
      }
    ]
  }
]
