/* global BigInt */
import TableCustom from "@components/Table/TableCustom"
import { ExpandIcon } from "@icons/index"
import { Constants } from "@void-0x/void-sdk"
import cx from "classnames"
import { useMemo } from "react"
import { Link } from "react-router-dom"
import useTradeHistory from "src/hooks/subgraph/useTradeHistory"
import { getChecksumAddress } from "src/lib/address"
import { isChainSupported } from "src/lib/chains"
import { timeAgo } from "src/lib/dates"
import { etherScan } from "src/lib/etherscan"
import { formatPrice, formatValue } from "src/lib/formatter"
import { AddressToSymbolMap, MapSymbolToIcon, Tokens, getSymbolFromPairName } from "src/lib/tokens"
import { useAccount, useNetwork } from "wagmi"

const columnDef = [
  {
    field: "Time",
    headerName: "Time",
    headerClassName: "text-xs text-left pl-3",
    className: "text-left pl-3",
    cellRenderer: (cell) => {
      return <p>{timeAgo(cell?.createdAt)}</p>
    }
  },
  {
    field: "Market",
    headerName: "Market",
    headerClassName: "text-xs text-left pl-3 w-36",
    cellRenderer: (cell) => {
      const pairName = getSymbolFromPairName(cell?.market?.name)
      const icon = MapSymbolToIcon[pairName]

      return (
        <div className="">
          <div className="flex items-center gap-2 text-left">
            <img src={icon} alt="token" className="w-6 h-6  inline-block" />
            <div className="text-left">
              <p>{cell?.market?.name}</p>
            </div>
          </div>
        </div>
      )
    }
  },
  {
    field: "status",
    headerName: "Operation",
    headerClassName: "text-xs",
    className: "capitalize"
  },
  {
    field: "Side",
    headerName: "Side",
    headerClassName: "text-xs",
    className: "capitalize",
    cellRenderer: (cell) => {
      return (
        <div className={cx({ "text-error": !cell?.isLong, "text-success": cell?.isLong })}>
          {cell?.isLong ? "Long" : "Short"}
        </div>
      )
    }
  },
  {
    field: "executedPrice",
    headerName: "Price",
    headerClassName: "text-xs",
    formatter: (val) => {
      return formatPrice(BigInt(val?.executedPrice), Constants.USD_DECIMALS)
    }
  },
  {
    field: "Collateral",
    headerName: "Collateral",
    headerClassName: "text-xs",
    cellRenderer: (cell) => {
      const chainId = cell?.chainId
      const collateralToken = getChecksumAddress(cell?.collateralToken)
      const symbol = AddressToSymbolMap[chainId][collateralToken]
      const token = Tokens[chainId][symbol]
      return (
        <div className="w-full md:w-1/2 2xl:w-1/3 m-auto">
          <div className="flex items-center gap-2">
            <img src={token?.icon} alt="token" className="w-6 h-6  inline-block" />
            <div className="text-left">
              <p>{formatValue(cell.collateralValue, Constants.USD_DECIMALS)}</p>
            </div>
          </div>
        </div>
      )
    }
  },
  {
    field: "feeUsd",
    headerName: "Fee",
    headerClassName: "text-xs ",
    formatter: (val) => {
      return formatValue(BigInt(val?.feeUsd), Constants.USD_DECIMALS)
    }
  },
  {
    field: "pnlAndRoe",
    headerName: "Realized PNL &ROE ",
    headerClassName: "text-xs  break-normal",
    cellRenderer: (cell) => {
      return <p>{formatValue(BigInt(cell?.pnl || 0), Constants.USD_DECIMALS)}</p>
    }
  },
  {
    field: "Expand",
    headerName: "Expand",
    headerClassName: "text-xs",
    cellRenderer: (cell) => {
      const etherLink = etherScan[cell?.chainId]
      const txHash = cell?.txHash
      const destination = etherLink + txHash

      return (
        <Link className="flex items-center justify-center" to={destination} target="_blank">
          <img className="w-4 h-4" alt="expand" src={ExpandIcon} />
        </Link>
      )
    }
  }
]

const TradeHistory = () => {
  const { chain } = useNetwork()
  const { address } = useAccount()

  const histories = useTradeHistory(chain?.id, address)

  const formattedData = useMemo(() => {
    if (isChainSupported(chain.id)) return []

    return histories && histories.length > 0
      ? histories.map((history) => {
          return {
            ...history,
            chainId: chain?.id,
            id: history.id
          }
        })
      : []
  }, [histories, chain])

  return (
    <>
      <div className="vh-20 w-full overflow-y-auto no-scrollbar ">
        <TableCustom columnDef={columnDef} data={formattedData} cellStyle="p-3 text-xs" isBorderHeader={false} />
      </div>
    </>
  )
}

export default TradeHistory
