/* global BigInt */

import React, { useCallback, useEffect, useMemo, useState } from "react"
import Modal from "@components/Modal/Modal"
import { useExchangeUIContext } from "src/contexts/ExchangeUIContext"
import cx from "classnames"
import Button from "@components/Button/Button"
import { formatValue } from "src/lib/formatter"
import { useExchangeContext } from "src/contexts/ExchangeContext"
import { OrderType, Side } from "@void-0x/void-sdk"
import { ArrowLongRightIcon } from "@icons/index"

const ConfirmCloseModal = ({ open, setOpen, disabled }) => {
  const { modalCloseOrderConfirm, setModalCloseOrderConfirm, setListOrders } = useExchangeUIContext()
  const { closeOrder, isClosingOrder } = useExchangeContext()

  const [sizeAmount, setSizeAmmount] = useState(modalCloseOrderConfirm?.data?.raw?.size)

  useEffect(() => {
    setSizeAmmount(modalCloseOrderConfirm?.data?.raw?.size)
  }, [modalCloseOrderConfirm?.data?.raw?.size])

  const calculateSize = useCallback(
    (percent) => {
      if (modalCloseOrderConfirm?.data?.raw?.size && modalCloseOrderConfirm?.enabled) {
        return (modalCloseOrderConfirm?.data?.raw?.size * BigInt(percent)) / BigInt(100)
      }
      return BigInt(0)
    },
    [modalCloseOrderConfirm?.data?.raw?.size, modalCloseOrderConfirm?.enabled]
  )

  const handleCloseOrder = useCallback(async () => {
    const rawPosition = modalCloseOrderConfirm?.data?.raw
    const dataPopup = modalCloseOrderConfirm?.data

    const closeItem = {
      orderId: Math.random(),
      orderType: dataPopup.type,
      side: dataPopup.type,
      collateralToken: dataPopup.icon,
      collateralAmount: dataPopup.collateralValue,
      leverage: dataPopup.leverage,
      indexPrice: dataPopup.indexPrice,
      token: dataPopup.icon,
      action: "close"
    }

    await closeOrder({
      orderType: OrderType.MARKET,
      side: rawPosition.isLong ? Side.LONG : Side.SHORT,
      marketId: rawPosition.market.id,
      collateralToken: rawPosition.collateralToken,
      // TODO: dynamic collateral delta
      sizeDelta: BigInt(rawPosition.size),
      extra: {
        triggerPrice: BigInt(0),
        tpPrice: BigInt(0),
        tpDelta: BigInt(0),
        slPrice: BigInt(0),
        slDelta: BigInt(0)
      }
    })

    setListOrders((prev) => [...prev, closeItem])

    setTimeout(() => {
      setListOrders([])
    }, 3000)

    if (!isClosingOrder) {
      setOpen(false)
      setModalCloseOrderConfirm({ enabled: false, data: null })
    }
  }, [closeOrder, isClosingOrder, modalCloseOrderConfirm?.data, setListOrders, setModalCloseOrderConfirm, setOpen])

  const headerConfirmModal = useMemo(() => {
    return (
      <div>
        <div className="flex gap-3">
          <div>Close {modalCloseOrderConfirm?.data?.token} Position -</div>
          <div
            className={cx(
              {
                "text-success": modalCloseOrderConfirm?.data?.type === "long",
                "text-error": modalCloseOrderConfirm?.data?.type === "short"
              },
              "capitalize"
            )}
          >
            {modalCloseOrderConfirm?.data?.type} {modalCloseOrderConfirm?.data?.leverage}
          </div>
        </div>
      </div>
    )
  }, [modalCloseOrderConfirm])

  const bodyConfirmModal = useMemo(() => {
    return (
      modalCloseOrderConfirm &&
      modalCloseOrderConfirm.data && (
        <div className="flex flex-col gap-5 ">
          <div className="grid grid-cols-2 gap-3">
            <div className="border py-2 px-2 rounded text-left ">
              <h5 className="text-slate-500 text-sm">Market Price</h5>
              <div className="text-sm">{modalCloseOrderConfirm?.data?.indexPrice}</div>
            </div>
            <div className="border py-2 px-2 rounded text-left">
              <h5 className="text-slate-500 text-sm">Order Type</h5>
              <div className="text-sm">Market</div>
            </div>
          </div>
          <div className="border p-2 flex flex-col gap-3 rounded">
            <div className="flex justify-between">
              <h5 className="text-sm text-slate-500">Close Amount</h5>
              <div className="text-slate-500 text-sm">Max: {modalCloseOrderConfirm?.data?.size}</div>
            </div>
            <div className="flex justify-between">
              <div className="text-sm">
                {sizeAmount && modalCloseOrderConfirm?.enabled
                  ? formatValue(sizeAmount, modalCloseOrderConfirm?.data?.raw?.valueDecimals)
                  : ""}
              </div>
              <div className="text-sm">{modalCloseOrderConfirm?.data?.token}</div>
            </div>
            <div className="grid grid-cols-4 gap-3 text-sm">
              {[25, 50, 75, 100].map((i, idx) => (
                <div
                  className={cx("bg-gray-800 py-1 rounded cursor-pointer", {
                    "bg-default": sizeAmount === calculateSize(i)
                  })}
                  onClick={() => {
                    const amount = calculateSize(i)
                    setSizeAmmount(amount)
                  }}
                  key={idx}
                >
                  {i} %
                </div>
              ))}
            </div>
          </div>
          <div className="flex justify-between items-center">
            <h5 className="text-slate-500 text-sm">Profits In</h5>
            <div className="flex items-center gap-1">
              <img src={modalCloseOrderConfirm?.data?.icon} className="w-4 h-4" alt="token" />
              <div className="text-sm">{modalCloseOrderConfirm?.data?.token}</div>
            </div>
          </div>
          <div className="w-full h-1 bg-slate-800"></div>
          <div className="flex flex-col gap-2">
            <div className="flex text-sm items-center justify-between">
              <h5 className="text-slate-500">Entry & Index Price</h5>
              <div className="text-sm">{modalCloseOrderConfirm?.data?.entryPrice}</div>
            </div>
            <div className="flex text-sm items-center justify-between">
              <h5 className="text-slate-500">Market</h5>
              <div className="text-sm">{modalCloseOrderConfirm?.data?.market}</div>
            </div>
            <div className="flex text-sm items-center justify-between">
              <h5 className="text-slate-500">Size</h5>
              <div className="text-sm">
                <div className="flex items-center gap-2">
                  <span className="text-slate-500">{modalCloseOrderConfirm?.data?.size}</span>
                  <img src={ArrowLongRightIcon} alt="arrow right" className="w-5 h-5" />
                  <span>
                    {sizeAmount && modalCloseOrderConfirm?.enabled
                      ? formatValue(sizeAmount, modalCloseOrderConfirm?.data?.raw?.valueDecimals)
                      : ""}
                  </span>
                </div>
              </div>
            </div>
            <div className="flex text-sm items-center justify-between">
              <h5 className="text-slate-500">Leverage</h5>
              <div className="text-sm flex items-center gap-1">
                <div className="">{modalCloseOrderConfirm?.data?.leverage}</div>
              </div>
            </div>
            <div className="flex text-sm items-center justify-between">
              <h5 className="text-slate-500">Liq. Price</h5>
              <div className="text-sm">
                <div>{modalCloseOrderConfirm?.data?.liquidationPrice}</div>
              </div>
            </div>
            <div className="flex text-sm items-center justify-between">
              <h5 className="text-slate-500">Collateral</h5>
              <div className="text-sm">{modalCloseOrderConfirm?.data?.collateralValue}</div>
            </div>
            <div className="flex text-sm items-center justify-between">
              <h5 className="text-slate-500">Pnl & Roe</h5>
              <div className="text-sm flex items-center gap-1">
                <div
                  className={cx({
                    "text-success": modalCloseOrderConfirm?.data?.isProfitable,
                    "text-error": !modalCloseOrderConfirm?.data?.isProfitable
                  })}
                >
                  {modalCloseOrderConfirm?.data?.pnlRoe}
                </div>
              </div>
            </div>
            <div>
              <div className="text-sm flex items-center justify-between">
                <h5 className="text-slate-500 ">Net Value</h5>
                <div className="dotted-underline">{modalCloseOrderConfirm?.data?.netValue}</div>
              </div>
            </div>
          </div>

          <div className="w-full h-1 bg-slate-800"></div>
        </div>
      )
    )
  }, [calculateSize, modalCloseOrderConfirm, sizeAmount])

  const footerConfirmModal = useMemo(() => {
    return (
      <div>
        <Button text="Close" onClick={handleCloseOrder} disabled={isClosingOrder} isLoading={isClosingOrder} />
      </div>
    )
  }, [handleCloseOrder, isClosingOrder])

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      header={headerConfirmModal}
      body={bodyConfirmModal}
      disabled={disabled}
      footer={footerConfirmModal}
    />
  )
}

export default ConfirmCloseModal
